import React, { useRef, useEffect, useState } from 'react'
import { DeviceType } from 'models/DeviceType'
import { MessageType } from 'models/MessageType'
import { SettingsType } from 'models/SettingsType'
import { PrevIcon, NextIcon } from 'icons/Icons'
import TemplateSlide from '../TemplateSlide'
import { usePreventFocus } from 'hooks/usePreventFocus'
import * as S from './TemplateMessage.style'

interface Props {
  device: DeviceType
  message: MessageType
  settings: SettingsType
  showLogo: boolean
  isMobile: boolean
  postMessage: (m: MessageType) => void
  isFullScreenWidget: boolean
  isFocusButton: boolean
  enableReaction: boolean
  addReaction: (e: any, reactionValue: string, messageId: string) => void
}

export const TemplateMessage: React.FC<Props> = ({
  isMobile,
  isFullScreenWidget,
  device,
  showLogo,
  settings,
  message,
  postMessage,
  isFocusButton,
  enableReaction,
  addReaction
}) => {
  const [showRightButton, setShowRightButton] = useState(true)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const carouselRef = useRef(null)
  const preventFocus = usePreventFocus()

  const updateButtonsDisplaying = () => {
    if (carouselRef?.current) {
      const { scrollLeft, clientWidth, scrollWidth } = carouselRef.current
      const showLeftButton = scrollLeft !== 0
      const showRightButton = scrollLeft + clientWidth < scrollWidth

      setShowLeftButton(showLeftButton)
      setShowRightButton(showRightButton)
    }
  }

  useEffect(() => {
    if (carouselRef?.current) {
      carouselRef.current.addEventListener('scroll', updateButtonsDisplaying, false)
      return () => {
        carouselRef.current?.removeEventListener('scroll', updateButtonsDisplaying, false)
      }
    }
  }, [])

  useEffect(() => {
    setTimeout(updateButtonsDisplaying, 0)
  }, [isFullScreenWidget])

  const handleScrollPrev = () => {
    scrollItems(false)
  }

  const handleScrollNext = () => {
    scrollItems(true)
  }

  const scrollItems = direction => {
    const width = 266
    carouselRef.current.scrollLeft += direction ? width : -width
  }

  return (
    <S.TemplateMessage tabIndex={preventFocus ? -1 : 0} aria-label={'template message'}>
      {!isMobile && showLeftButton && message.elements.length > 1 && (
        <S.PrevIconWrap data-autotest={'generic-list-prev-btn'} onClick={handleScrollPrev}>
          <PrevIcon />
        </S.PrevIconWrap>
      )}
      <S.SlidesWrap isFullScreenWidget={isFullScreenWidget} isMobile={isMobile} showLogo={showLogo} ref={carouselRef}>
        {message?.elements?.map((element, index) => (
          <TemplateSlide
            key={index}
            element={element}
            isRtl={message.isRtl}
            device={device}
            settings={settings}
            postMessage={postMessage}
            index={index}
            isFocusButton={isFocusButton && showLogo && index === 0}
            enableReaction={enableReaction}
            addReaction={addReaction}
            isMobile={isMobile}
            messageId={message.id}
            reaction={message.reaction}
          />
        ))}
      </S.SlidesWrap>
      {!isMobile && showRightButton && message.elements.length > 1 && (
        <S.NextIconWrap data-autotest={'generic-list-next-btn'} onClick={handleScrollNext}>
          <NextIcon />
        </S.NextIconWrap>
      )}
    </S.TemplateMessage>
  )
}
