import { API_URL } from '../config/config'
import { mockWidgetSettings } from '../config/mockSettings'

export const createJsonHeaders = () => {
  const headers = createHeaders()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return headers
}

function createHeaders() {
  return new Headers()
}

export function logoutOnRequestOrResponseJson(response) {
  if (response.ok) {
    return response.json()
  } else {
    throw Error(response.status + ' - ' + response.statusText)
  }
}

export const getWidgetSettings = (botId, chatId, language) => {
  const queryParam = chatId ? `chatId=${chatId}` : `language=${language}`

  return fetch(`${API_URL}/widget/info/${botId}?${queryParam}`, {
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(settings => settings.data)
      }
      if (response.status >= 400) {
        response.text().then(err => console.log(err))
        return mockWidgetSettings
      }
    })
    .catch(error => console.log(error))
}

export const getPersistentMenuApi = async(botId, chatId) => {
  return fetch(`${API_URL}/widget/${botId}/persistentMenu?chatId=${chatId}`, {
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(settings => settings)
      }
    })
    .catch(error => console.log(error))
}

export const sendAction = (actionType, botId, chatId, metadata) => {
  const event = {
    action: {
      type: actionType,
    },
    chatId,
  }

  if (metadata) {
    event.action.metadata = metadata
  }

  if (chatId) {
    return fetch(`${API_URL}/widget/${botId}/chat/${chatId}/action`, {
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(event),
    }).catch(error => console.log(error))
  }
}

export const getGreetingText = (botId, attributes, language, chatId) => {
  const url = new URL(`${API_URL}/widget/${botId}/greeting`)
  const id = chatId

  attributes.forEach(attribute => {
    url.searchParams.append(attribute.name, attribute.value)
  })

  if (id) {
    url.searchParams.append('chatId', id)
  }

  if (language) {
    url.searchParams.append('language', language)
  }

  return fetch(url, {
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(greetingText => greetingText.data || null)
      }
    })
    .catch(error => console.log(error))
}

export const sendGreetingEvent = (event, botId, hash, chatId, eventId) => {
  const body = {
    hash,
    event,
  }

  if (chatId) body.chatId = chatId
  if (eventId) body.id = eventId

  return fetch(`${API_URL}/widget/${botId}/greeting/event`, {
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(res => res.data)
      }
    })
    .catch(error => console.log(error))
}

export const assignGreeting = (botId, chatId, id) => {
  const body = {
    chatId,
    id,
  }

  fetch(`${API_URL}/widget/${botId}/greeting/event`, {
    headers: createJsonHeaders(),
    method: 'PATCH',
    body: JSON.stringify(body),
  }).catch(error => console.log(error))
}

export const sendFeedback = (feedback, chatId, botId) => {
  return fetch(`${API_URL}/bot/${botId}/analytics/feedback/${chatId}`, {
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(feedback),
  })
}

export const getBotPersonality = async(botId, chatId) => {
  return fetch(`${API_URL}/bot/${botId}/personality/${chatId}`, {
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(res => res.data)
      }
    })
    .catch(error => console.log(error))
}
