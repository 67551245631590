import React, { useContext, useEffect, useLayoutEffect } from 'react'

import '../Message.css'
import TextMessage from '../TextMessage'
import ImageMessage from '../ImageMessage'
import TemplateMessage from '../TemplateMessage'
import { NewMessagesLine } from '../NewMessagesLine'
import { MessageWrapper } from '../MessageWrapper'
import { ActionMessage } from '../ActionMessage'
import { SettingsType } from 'models/SettingsType'
import { DeviceType } from 'models/DeviceType'
import { MessageType } from 'models/MessageType'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import { sendAction } from 'api/api'
import { READ } from 'constants/statuses'
import { REACTION } from 'constants/actionsType'
import { getReactionsTitle } from 'helper/getReactionsTitle'
import { ReactionBarSelector } from '../../ReactionBarSelector/ReactionBarSelector'
import { MessageType as MsgType } from 'constants/index'

interface Props {
  message: MessageType
  postMessage: (m: MessageType) => void
  parentURL: string
  showLogo: boolean
  showReply: boolean
  device: DeviceType
  settings: SettingsType
  isFullScreenWidget: boolean
  messageBorder: string
  isMobile: boolean
  firstUnreadMessageId: string
  firstUnreadMessageRef: string
  isLastMessage: boolean
  setIsClickedOnReaction: (value: boolean) => void
  showReactionElementText: boolean
}

const MessageBody: React.FC<Props> = ({
  message,
  postMessage,
  parentURL,
  showLogo,
  showReply,
  device,
  settings,
  isFullScreenWidget,
  messageBorder,
  isMobile,
  firstUnreadMessageId,
  firstUnreadMessageRef,
  isLastMessage,
  setIsClickedOnReaction,
  showReactionElementText,
}) => {
  const { chatId, botId, sendStatus, addReactionToCurrentMessages }: any = useContext(WebSocketsSharedContext)

  useEffect(() => {
    onVisibilityChange()
  }, [])

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible' && message?.isFromBot && message?.status !== READ) {
      sendStatus(READ, message.id)
    }
  }

  const addReaction = (e, reactionValue, messageId) => {
    e.stopPropagation()

    const metadata = {
      messageId: messageId,
      title: getReactionsTitle(reactionValue),
      value: reactionValue,
    }

    sendAction(REACTION, botId, chatId, metadata).then(res => {
      if (res.ok) {
        addReactionToCurrentMessages(metadata)
        setIsClickedOnReaction(true)
      }
    })
  }

  const renderMessage = () => {
    if ((message.type === MsgType.text || message.type === MsgType.streamableText) && message.text) {
      return (
        <TextMessage
          device={device}
          settings={settings}
          postMessage={postMessage}
          message={message}
          messageBorder={messageBorder}
          showLogo={showLogo}
          isFocusButton={isLastMessage}
          enableReaction={message.enableReaction}
          addReaction={addReaction}
          isMobile={isMobile}
        />
      )
    } else if (message.type === MsgType.genericTemplate) {
      return (
        <TemplateMessage
          device={device}
          message={message}
          settings={settings}
          showLogo={showLogo}
          isMobile={isMobile}
          postMessage={postMessage}
          isFullScreenWidget={isFullScreenWidget}
          isFocusButton={isLastMessage}
          enableReaction={message.enableReaction}
          addReaction={addReaction}
        />
      )
    } else if (message.type === MsgType.attachment) {
      return (
        <ImageMessage
          device={device}
          parentURL={parentURL}
          url={message.imageUrl}
          isFullScreenWidget={isFullScreenWidget}
          enableReaction={message.enableReaction}
          addReaction={addReaction}
          isFocusButton={isLastMessage}
          isMobile={isMobile}
          messageId={message.id}
          reaction={message.reaction}
        />
      )
    }
  }

  return (
    <>
      {firstUnreadMessageId && message?.id && firstUnreadMessageId === message.id && (
        <NewMessagesLine firstUnreadMessageRef={firstUnreadMessageRef} settings={settings} />
      )}

      {message?.action ? (
        <ActionMessage action={message?.action} settings={settings} />
      ) : (
        <MessageWrapper
          showReply={showReply}
          showLogo={showLogo}
          message={message}
          settings={settings}
          postMessage={postMessage}
          isLastMessage={isLastMessage}>
          {renderMessage()}
          {message.enableReaction && (
            <ReactionBarSelector
              onClick={addReaction}
              isMobile={isMobile}
              reaction={message.reaction}
              messageId={message.id}
              showReactionElementText={showReactionElementText}
            />
          )}
        </MessageWrapper>
      )}
    </>
  )
}

export default MessageBody
