import styled from 'styled-components'
import { ATHENS_GREY, RIBBON_BLUE } from 'constants/colors'

const MessageButton = styled.button`
  padding: 8px;
  background: none;
  display: block;
  font-size: 16px;
  border: none;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border-top: 1px solid ${ATHENS_GREY};
  color: ${({ color }) => color || RIBBON_BLUE};
  text-align: center;
  user-select: none;
  outline: none;
  word-break: break-word;
`

export { MessageButton }
