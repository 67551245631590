import React from 'react'

const EndConversationModalContext = React.createContext({
  isOpenEndConversationModal: false,
  setIsOpenEndConversationModal: undefined,
})

const EndConversationModalProvider = props => {
  const [isOpenEndConversationModal, setIsOpenEndConversationModal] = React.useState(false)

  const value = React.useMemo(
    () => ({
      isOpenEndConversationModal,
      setIsOpenEndConversationModal,
    }),
    [isOpenEndConversationModal],
  )

  return <EndConversationModalContext.Provider value={value}>{props.children}</EndConversationModalContext.Provider>
}

export { EndConversationModalProvider, EndConversationModalContext }
