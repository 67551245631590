import React, { useContext, useState } from 'react'
import _ from 'lodash'

import StartScreenHeader from 'components/StartScreenHeader'
import { isLettersOnly } from 'helper/isLettersOnly'
import { isValidEmail } from 'helper/isValidEmail'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import { DeviceType } from 'models/DeviceType'
import { SettingsType } from 'models/SettingsType'

import * as S from './StartScreen.style'

interface Props {
  device: DeviceType
  settings: SettingsType
  greeting: any
  popupWasShow: boolean
  updateUserData: (a: any) => void
}

export const StartScreen: React.FC<Props> = ({ device, settings, greeting, popupWasShow, updateUserData }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({ name: '', email: '' })
  const [isDisplayArea, setIsDisplayArea] = useState(false)
  const { initializeConversation }: any = useContext(WebSocketsSharedContext) || {}
  const { isLoginEmailInputEnabled, isLoginNameInputEnabled, isRtl } = settings

  const handleChangeName = event => {
    setName(event.target.value.trimStart())
  }

  const handleChangeEmail = event => {
    setEmail(event.target.value.trimStart())
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const isValid = validateForm()

    setIsDisplayArea(false)

    if (isValid) {
      const user = { name, email }
      updateUserData(user)
      handleInitialize()
    } else {
      setTimeout(() => setIsDisplayArea(true), 100)
    }
  }

  const validateForm = () => {
    const err = Object.assign({}, errors)

    if (isLoginNameInputEnabled) {
      err.name = (!isLettersOnly(name) || _.isEmpty(name)) && settings.nameInputError
    }

    if (isLoginEmailInputEnabled) {
      if (_.isEmpty(email)) {
        err.email = settings.emailInputEmptyError
      } else if (!isValidEmail(email)) {
        err.email = settings.emailInputError
      } else {
        err.email = ''
      }
    }

    setErrors(err)

    return !err.name && !err.email
  }

  const handleInitialize = () => {
    if (greeting?.payload && popupWasShow) {
      const message = {
        type: 'postback',
        payload: greeting.payload,
        isRtl: false,
      }
      initializeConversation(message)
    } else {
      initializeConversation()
    }
  }

  return (
    <S.StartScreen width={device?.width} tabIndex={0} aria-label="Welcome chatbot screen">
      <StartScreenHeader settings={settings} />
      <S.Form onSubmit={handleSubmit}>
        <S.Description dir={isRtl ? 'rtl' : 'ltr'} tabIndex={0}>
          {settings.startLabel}
        </S.Description>

        {isLoginNameInputEnabled && (
          <S.Input
            placeholder={settings.nameInputPlaceholder}
            value={name}
            error={errors.name}
            withButton={isLoginNameInputEnabled && !isLoginEmailInputEnabled}
            onChange={handleChangeName}
            dir={isRtl ? 'rtl' : 'ltr'}
            id="widget-login-name-input"
            autoComplete="off"
          />
        )}

        {errors.name && (
          <S.Error dir={isRtl ? 'rtl' : 'ltr'}>
            {isDisplayArea && <div aria-label={errors.name} tabIndex={0} aria-live={'assertive'} />}
            {errors.name}
          </S.Error>
        )}

        {isLoginEmailInputEnabled && (
          <S.Input
            placeholder={settings.emailInputPlaceholder}
            value={email}
            error={errors.email}
            withButton={!isLoginNameInputEnabled && isLoginEmailInputEnabled}
            onChange={handleChangeEmail}
            dir={isRtl ? 'rtl' : 'ltr'}
            id="widget-login-email-input"
            autoComplete="off"
          />
        )}

        {errors.email && (
          <S.Error dir={isRtl ? 'rtl' : 'ltr'} tabIndex={0} aria-live={'assertive'}>
            {isDisplayArea && <div aria-label={errors.email} tabIndex={0} aria-live={'assertive'} />}
            {errors.email}
          </S.Error>
        )}

        <S.Button
          id="widget-start-button"
          color={settings.color}
          type="submit"
          withInput={!(isLoginNameInputEnabled && isLoginEmailInputEnabled)}>
          {settings.startButton}
        </S.Button>
      </S.Form>
    </S.StartScreen>
  )
}
