import { parseAttributes } from './parseAttributes'
import _ from 'lodash'

export const generateMultiSelectPostBack = (reply, selectedQR) => {
  return {
    type: 'postback',
    postback: {
      payload: generatePayload(reply.payload, selectedQR),
      title: selectedQR.map(({ title }) => title).join(', '),
      postbackId: selectedQR.map(({ postbackId }) => postbackId).join(', '),
    },
  }
}

const generatePayload = (payload, selectedQR) => {
  const spliterator = '?attributes='
  const atom = payload.split(spliterator)[0]
  const selectedAttributes = selectedQR?.reduce((prev, curr) => [...prev, ...parseAttributes(curr.payload)], [])
  const uniqAttributes = _.uniqBy(selectedAttributes, 'id')
  const encodedAttributes = JSON.stringify(uniqAttributes).split('').join('\u0000')

  return atom + spliterator + '/v8A' + window.btoa(encodedAttributes)
}
