import React, { useContext } from 'react'
import { SettingsType } from 'models/SettingsType'
import * as S from './Popover.style'
import { useClickOutside } from 'hooks/useClickOutside'
import ResizeButton from 'components/resizeButton'
import { CloseWidgetIcon, SoundOffIcon, SoundOnIcon } from 'icons/Icons'
import { COMET_BLUE } from 'constants/colors'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { AriaLiveContext } from 'context/AriaLiveContext'

interface Props {
  isFullScreenWidget: boolean
  isMobile: boolean
  resizeWidget: (a: boolean) => void
  anchorEl: any
  parentEl: any
  onClose: () => void
  settings: SettingsType
  isSoundNotification: boolean
  handleSoundNotification: () => void
}

export const Popover: React.FC<Props> = ({
  isFullScreenWidget,
  isMobile,
  resizeWidget,
  anchorEl,
  parentEl,
  onClose,
  settings,
  isSoundNotification,
  handleSoundNotification,
}) => {
  const refPopover = useClickOutside(onClose, anchorEl)
  const { setIsOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { setIsEnableAriaLive } = useContext(AriaLiveContext)

  const resizeButtonTitle = isFullScreenWidget
    ? settings?.widgetActions?.minimizeWidgetTitle
    : settings?.widgetActions?.maximizeWidgetTitle
  const soundButtonTitle = isSoundNotification
    ? settings?.widgetActions?.soundOffTitle
    : settings?.widgetActions?.soundOnTitle

  const handleResize = () => {
    resizeWidget(!isFullScreenWidget)
    setIsEnableAriaLive(false)
    onClose()
  }

  const handleClose = () => {
    setIsOpenEndConversationModal(true)
    onClose()
  }

  return (
    <S.Popover
      ref={refPopover}
      eleRect={anchorEl?.current?.getBoundingClientRect()}
      targetRect={parentEl?.current?.getBoundingClientRect()}>
      {!isMobile && (
        <S.Item onClick={handleResize} id="widget-resize-button" data-testid={'button-resize'}>
          <ResizeButton isLarge={isFullScreenWidget} />
          {resizeButtonTitle}
        </S.Item>
      )}
      <S.Item
        onClick={handleSoundNotification}
        aria-label={`sound ${isSoundNotification ? 'off' : 'on'} widget notifications`}>
        {isSoundNotification ? <SoundOffIcon /> : <SoundOnIcon />}
        {soundButtonTitle}
      </S.Item>
      <S.Item onClick={handleClose} id="widget-end-conversation-button-popover" data-testid={'button-modal'}>
        <CloseWidgetIcon color={COMET_BLUE} />
        {settings?.widgetActions?.endConversationTitle}
      </S.Item>
    </S.Popover>
  )
}
