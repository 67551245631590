import React, { useContext } from 'react'
import { Modal } from '../Modal'
import ModalButton from '../ModalButton'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { sendAction } from 'api/api'
import { SettingsType } from 'models/SettingsType'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import { END_CONVERSATION } from 'constants/actionsType'
import { FeedbackModalContext } from '../../context/FeedbackModalContext'

import * as S from './EndCoversationModal.style'

interface Props {
  settings: SettingsType
  toggleWidget: () => void
}

export const EndConversationModal: React.FC<Props> = ({ settings, toggleWidget }) => {
  const { setIsOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { chatId, botId, finishChatWithAgent }: any = useContext(WebSocketsSharedContext)
  const { setIsOpenFeedbackModal } = useContext(FeedbackModalContext)

  const handleSubmit = () => {
    if (settings?.widgetSettings?.doAskForRating) {
      handleClose()
      setIsOpenFeedbackModal(true)
    } else {
      sendAction(END_CONVERSATION, botId, chatId).then(() => {
        handleClose()
        toggleWidget()
        finishChatWithAgent()
      })
    }
  }

  const handleClose = () => {
    setIsOpenEndConversationModal(false)
  }

  return (
    <Modal onClose={handleClose} ariaLabel={'end conversation'}>
      <S.Title tabIndex={1}>{settings?.endConversation?.title}</S.Title>
      <S.SubTitle tabIndex={1}>{settings?.endConversation?.subtitle}</S.SubTitle>
      <S.ButtonsWrap>
        <ModalButton
          type="cancel"
          title={settings?.promptButtons?.cancelTitle}
          onClick={handleClose}
          color={settings.color}
        />
        <ModalButton
          id="widget-end-conversation-button"
          type="submit"
          title={settings?.promptButtons?.endTitle}
          onClick={handleSubmit}
          color={settings.color}
          dataTestId="submit-modal"
        />
      </S.ButtonsWrap>
    </Modal>
  )
}
