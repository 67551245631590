import React from 'react'

const FeedbackModalContext = React.createContext({
  isOpenFeedbackModal: false,
  setIsOpenFeedbackModal: undefined,
})

const FeedbackModalProvider = props => {
  const [isOpenFeedbackModal, setIsOpenFeedbackModal] = React.useState(false)

  const value = React.useMemo(
    () => ({
      isOpenFeedbackModal,
      setIsOpenFeedbackModal,
    }),
    [isOpenFeedbackModal],
  )

  return <FeedbackModalContext.Provider value={value}>{props.children}</FeedbackModalContext.Provider>
}

export { FeedbackModalProvider, FeedbackModalContext }
