export const reactionsModelData = [
  {
    icon: '👍',
    value: 'thumbsup'
  },
  {
    icon: '👎',
    value: 'thumbsdown'
  }
]

export const reactionsIconsCollection = {
  thumbsup: '👍',
  thumbsdown: '👎'
}
