import React, { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  AGENT_JOINED,
  AGENT_LEFT,
  SHOW_INPUT,
  TYPING_ON,
  TYPING_OFF,
  SESSION_EXPIRED,
  END_CONVERSATION,
  INPUT_ON,
  INPUT_OFF,
  EXPERT_BOT_ENABLED,
  EXPERT_BOT_DISABLED
} from 'constants/actionsType'
import { getQueryVariable } from '../actions/helpers'
import { generatePostBackObject } from '../actions/static'
import { getChatHistoryRequest, getChatIdRequest } from '../api/websocketApi'
import { convertUserMessageToBepFormat } from '../api/messageConverter'
import WebSocketClient from '../api/WebSocketClient'
import { MESSAGE_SIZE } from '../constants'
import { MessageType, StreamActionType } from '../constants'
import { getMessagesFromHistory } from '../helper/getMessagesFromHistory'
import { getPostbackMessage } from '../helper/getPostbackMessage'
import { assignGreeting, getGreetingText, sendAction } from '../api/api'
import { detectSiteLanguageOrDefault } from '../app/config'
import { camelToSnakeCase } from '../helper/camelToSnakeCase'
import _ from 'lodash'

import { READ } from '../constants/statuses'
import { aggregateChunksFromQueue, normalizeStreamingMessage } from '../components/containers/Chat/MessageContainer'

export const WebSocketsSharedContext = React.createContext({ sendStatus: null })
export const WebSocketsSharedConsumer = WebSocketsSharedContext.Consumer

class WebSockets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      loadMore: true,
      showTyping: false,
      userData: {
        name: 'Widget user',
        email: '',
        chatId: null,
      },
      chatWithAgent: false,
    }
    this.wrongOrderingGPTChunks = createRef()
    this.wrongOrderingGPTChunks.current = []
    this.websocketClient = new WebSocketClient()
    this.websocketClient.configureEventHandler(this.handleWidgetReceiveEvent)
    this.websocketClient.configureLanguageUpdateHandler(message => this.handleLanguageUpdate(message))
  }

  componentDidMount() {
    const chatId = this.props.browserStorage.chatId
    const needUserInformation =
      this.props.settings.isLoginEmailInputEnabled || this.props.settings.isLoginNameInputEnabled

    this.setState({ chatId }, () => {
      if (chatId) {
        // eslint-disable-next-line max-len
        this.websocketClient.connect(
          this.props.botId,
          chatId,
          true,
          this.props.updateBrowserStorage,
          this.props.browserStorage.isConversationInitialized,
        )
        this.initializeConversation()
      } else if (this.props.openByDefault && !needUserInformation) {
        this.initializeConversation()
      }
    })
  }

  componentWillUnmount() {
    this.websocketClient.disconnect()
    this.props.closeWidget()
  }

  getChatId = message => {
    const { user, botId, attributes, websiteLocation, greeting, popupWasShow, eventId } = this.props
    const savedChatId = this.props.browserStorage.chatId
    const language = detectSiteLanguageOrDefault(websiteLocation)

    const data = {
      botId,
      chatId: savedChatId,
      name: user?.name || 'Widget user',
      email: user?.email || '',
      language: user?.language || 'en',
      attributes: attributes.map(att => ({
        value: att.value,
        name: camelToSnakeCase(att.name),
      })),
    }

    getChatIdRequest(data)
      .then(chatId => {
        if (!savedChatId) {
          const initiateConversation = !greeting?.payload || !popupWasShow

          data.chatId = chatId
          this.props.updateBrowserStorage({ chatId })
          // eslint-disable-next-line max-len
          this.websocketClient.connect(
            botId,
            chatId,
            initiateConversation,
            this.props.updateBrowserStorage,
            this.props.browserStorage.isConversationInitialized,
          )
        }
        this.websocketClient.configureChatId(chatId)
        this.websocketClient.configureBotId(botId)
        this.setState({ userData: data }, () => this.getChatHistory(chatId))
        return chatId
      })
      .then(chatId => {
        if (greeting && !savedChatId && popupWasShow && eventId) {
          assignGreeting(botId, chatId, eventId)
        }
        getGreetingText(botId, attributes, language, chatId).then(greeting => this.props.updateGreeting(greeting))
        this.props.setPersistentMenu(botId, chatId)
      })
      .then(() => {
        this.props.updatePopupWasShow()
        if (message) {
          const data = generatePostBackObject(message)
          this.postMessage(data)
        }
      })
  }

  sendRefMessage = ref => {
    this.postMessage({
      type: 'postback',
      payload: ref,
    })
    this.props.updateBrowserStorage({ ref })
  }

  postMessage = message => {
    const { userData } = this.state
    const { websiteLocation, botId } = this.props

    const data = convertUserMessageToBepFormat(userData.chatId, message, websiteLocation)
    this.websocketClient.sendMessage(data, botId, userData.chatId)
  }

  getChatHistory = chatId => {
    if (this.props.messages.length > 0 || !chatId) {
      return
    }


    getChatHistoryRequest(chatId, this.props.botId, MESSAGE_SIZE).then(res => {
      const loadMore = res && res.length === MESSAGE_SIZE

      this.setState(
        {
          page: this.state.page + 1,
          loadMore,
        },
        () => {
          if (res && res.length) {
            this.setHistory(res)
          } else {
            this.props.setIsEnableAriaLive(true)
          }
        },
      )
    })
  }

  getMoreChatHistory = () => {
    if (this.state.loadMore && this.state.chatId) {
      getChatHistoryRequest(this.state.chatId, this.props.botId, MESSAGE_SIZE, this.state.page)
        .then(res => {
          const loadMore = res && res.length === MESSAGE_SIZE

          this.setState(
            {
              page: this.state.page + 1,
              loadMore,
            },
            () => {
              if (res && res.length) {
                this.setMoreHistory(res)
              }
            },
          )
        })
        .catch(error => console.log(error))
    }
  }

  addReactionToCurrentMessages = reactionData => {
    const { messages } = this.props
    const updatedMessages = messages.map(message => {
      if (message.id === reactionData.messageId) {
        message.reaction = {
          title: reactionData.title,
          value: reactionData.value,
        }
      }
      return message
    })
    this.props.replaceMessages(updatedMessages)
  }

  setHistory(history) {
    this.props.updateMessages(getMessagesFromHistory(history))

    const ref = getQueryVariable('ref')
    if (ref !== 'false' && ref !== this.props.browserStorage.ref) {
      this.sendRefMessage(ref)
    }
  }

  setMoreHistory(history) {
    this.props.updateMessages(getMessagesFromHistory(history), undefined, true)
  }

  handleWidgetReceiveEvent = event => {
    const { message, actions, isFromBot, status } = event

    if (actions) {
      this.handleWidgetReceivedActions(actions)
    }

    if (message) {
      message.type === MessageType.streamableText
        ? this.handleWidgetReceiveGPTMessage({ ...message, isFromBot })
        : this.handleWidgetReceivedMessages(message, isFromBot)
    }

    if (status?.status === READ && !isFromBot) {
      this.handleWidgetReceivedStatus(status)
    }
  }

  handleWidgetReceivedMessages = (message, isFromBot) => {
    const { processMessage } = this.state
    const {
      isHiddenWidget,
      setUnreadMessages,
      unreadMessages,
      settings,
      firstUnreadMessageId,
      setFirstUnreadMessageId,
      handleAddUnreadMessages,
      messages,
      isSomewhereWidgetOpen,
    } = this.props
    let copyMessage = Object.assign({}, message)
    copyMessage.isFromBot = !!isFromBot

    if (copyMessage.type === 'postback') {
      copyMessage = getPostbackMessage(message)
    }

    if (isFromBot && messages.length && !isSomewhereWidgetOpen) {
      if (isHiddenWidget) {
        setUnreadMessages(unreadMessages + 1)
        if (settings.widgetSettings.doShowPopupMessagePreview && copyMessage.text) {
          handleAddUnreadMessages(copyMessage, this.props.websiteLocation)
        }
      }
      if (isHiddenWidget && !firstUnreadMessageId) {
        setFirstUnreadMessageId(copyMessage.id)
      }
      this.updatePreviousMessageStatus()
    }

    processMessage([copyMessage])
  }

  updatePreviousMessageStatus = () => {
    const { messages } = this.props

    if (messages.length) {
      const messagesCopy = _.cloneDeep(messages)
      for (let i = messagesCopy.length - 1; i > 0; i--) {
        if (messagesCopy[i]?.isFromBot) {
          break
        } else {
          if (messagesCopy[i]) {
            messagesCopy[i].status = READ
          }
        }
      }
      this.props.replaceMessages(messagesCopy)
    }
  }

  handleWidgetReceiveGPTMessage = message => {
    const {
      messages,
      openWidget,
      setIsProcessingGPTMessage,
      setGPTMessagesQueue,
      resetGPTMessagesQueue,
      GPTMessagesQueue,
    } = this.props

    if (message.stream.action === StreamActionType.START) {
      setIsProcessingGPTMessage(true)
    } else if (message.stream.action === StreamActionType.ERROR) {
      setIsProcessingGPTMessage(false)
      resetGPTMessagesQueue()
      this.wrongOrderingGPTChunks.current = []
      return
    }

    const lastGPTChunk = GPTMessagesQueue.at(-1)
    const lastGPTChunkIndex = lastGPTChunk && lastGPTChunk.stream?.index
    const incomingGPTChunkIndex = message.stream?.index
    const isWrongOrder = incomingGPTChunkIndex - lastGPTChunkIndex > 1

    // Second condition is required to handle cases when the first incoming chunk is 1-indexed
    if (!isWrongOrder && !(lastGPTChunk === undefined && incomingGPTChunkIndex === 1)) {
      setGPTMessagesQueue(message)
      if (this.wrongOrderingGPTChunks.current.length) {
        this.processWrongOrderingChunks(incomingGPTChunkIndex, setGPTMessagesQueue)
      }
    } else {
      this.wrongOrderingGPTChunks.current.push(message)
    }

    if (messages.length && !openWidget) {
      this.handleGPTMessagesForClosedWidget()
    }
  }

  processWrongOrderingChunks = (lastCorrectIndex, setGPTMessagesQueue) => {
    this.wrongOrderingGPTChunks.current.sort((msg1, msg2) => msg1.stream.index - msg2.stream.index)
    for (let i = 0; i < this.wrongOrderingGPTChunks.current.length; i++) {
      const wrongChunk = this.wrongOrderingGPTChunks.current[i]
      const wrongChunkIndex = wrongChunk?.stream?.index
      if (wrongChunkIndex - lastCorrectIndex === 1) {
        setGPTMessagesQueue(wrongChunk)
        this.wrongOrderingGPTChunks.current.shift()
        lastCorrectIndex = wrongChunkIndex
        i-- //NOSONAR
      }
    }
  }

  handleGPTMessagesForClosedWidget = () => {
    const { GPTMessagesQueue } = this.props

    const firstMessage = GPTMessagesQueue.at(0)
    const lastMessage = GPTMessagesQueue.at(-1)
    const areAllChunksPresent =
      firstMessage &&
      firstMessage.stream.action === StreamActionType.START &&
      lastMessage &&
      lastMessage.stream.action === StreamActionType.STORED

    if (areAllChunksPresent) {
      this.handleGPTMessagesWithStartAction()
    } else if (lastMessage && lastMessage.stream.action === StreamActionType.STORED) {
      this.handleGPTMessagesWithoutStartAction()
    }
  }

  handleGPTMessagesWithStartAction = () => {
    const { processMessage } = this.state
    const {
      resetGPTMessagesQueue,
      unreadMessages,
      setUnreadMessages,
      settings,
      handleAddUnreadMessages,
      firstUnreadMessageId,
      setFirstUnreadMessageId,
      setIsProcessingGPTMessage,
      isHiddenWidget,
      isSomewhereWidgetOpen,
    } = this.props

    const isHiddenAndNotOpenedAnywhere = isHiddenWidget && !isSomewhereWidgetOpen

    if (isHiddenAndNotOpenedAnywhere) {
      setUnreadMessages(unreadMessages + 1)
    }
    const fullGPTMessage = aggregateChunksFromQueue(this.props.GPTMessagesQueue)

    if (
      isHiddenAndNotOpenedAnywhere &&
      settings.widgetSettings.doShowPopupMessagePreview &&
      fullGPTMessage.stream.text
    ) {
      handleAddUnreadMessages(normalizeStreamingMessage(fullGPTMessage), this.props.websiteLocation)
    }
    if (isHiddenAndNotOpenedAnywhere && !firstUnreadMessageId) {
      setFirstUnreadMessageId(normalizeStreamingMessage(fullGPTMessage).id)
    }

    this.updatePreviousMessageStatus()
    processMessage([normalizeStreamingMessage(fullGPTMessage)])
    setIsProcessingGPTMessage(false)
    resetGPTMessagesQueue()
  }

  handleGPTMessagesWithoutStartAction = () => {
    const { chatId, processMessage } = this.state
    const {
      botId,
      messages,
      settings,
      unreadMessages,
      setUnreadMessages,
      firstUnreadMessageId,
      setFirstUnreadMessageId,
      handleAddUnreadMessages,
      setIsProcessingGPTMessage,
      resetGPTMessagesQueue,
      isHiddenWidget,
      isSomewhereWidgetOpen,
    } = this.props

    getChatHistoryRequest(chatId, botId, MESSAGE_SIZE).then(chatHistory => {
      const freshMessages = getMessagesFromHistory(chatHistory).filter(({ id }) => !messages.some(msg => msg.id === id))
      if (freshMessages.length && settings.widgetSettings.doShowPopupMessagePreview) {
        const firstUnreadMessage = freshMessages.at(-1)

        if (isHiddenWidget && !isSomewhereWidgetOpen) {
          setUnreadMessages(unreadMessages + 1)
          handleAddUnreadMessages(firstUnreadMessage, this.props.websiteLocation)
          if (!firstUnreadMessageId) {
            setFirstUnreadMessageId(firstUnreadMessage.id)
          }
        }
        this.updatePreviousMessageStatus()
        processMessage(freshMessages)
        setIsProcessingGPTMessage(false)
        resetGPTMessagesQueue()
      }
    })
  }

  handleWidgetReceivedActions = actions => {
    const { processMessage, processTyping } = this.state
    const { setInputField, setTextTogglerValue, setBotPersonality } = this.props

    actions.forEach(action => {
      if ([AGENT_JOINED, AGENT_LEFT, SESSION_EXPIRED, END_CONVERSATION].includes(action.type)) {
        processMessage([{ action }])
      }

      switch (action.type.toLowerCase()) {
        case TYPING_ON: {
          processTyping()
          break
        }
        case TYPING_OFF: {
          this.updateTyping(false)
          break
        }
        case SHOW_INPUT: {
          setInputField(true)
          break
        }
        case INPUT_ON:
        case INPUT_OFF: {
          setTextTogglerValue(action.type)
          break
        }
        case AGENT_JOINED: {
          this.setState({
            chatWithAgent: true,
          })
          break
        }
        case AGENT_LEFT: {
          this.finishChatWithAgent()
          break
        }
        case EXPERT_BOT_ENABLED: {
          setBotPersonality('EXPERT') // BotPersonality.enum
          break
        }
        case EXPERT_BOT_DISABLED: {
          setBotPersonality('COMPANION') // BotPersonality.enum
          break
        }
      }
    })
  }

  handleWidgetReceivedStatus = data => {
    let messages = _.cloneDeep(this.props.messages)
    messages = messages.map(message => {
      if (message.id === data.messageId) {
        message.status = data.status
      }
      return message
    })

    if (!_.isEqual(messages, this.props.messages)) {
      this.props.replaceMessages(messages)
    }
  }

  handleLanguageUpdate = message => {
    const { botId, updateWidget, setPersistentMenu } = this.props
    const { chatId } = this.state
    const language = message.shortName

    updateWidget(botId, language)
    setPersistentMenu(botId, chatId)
  }

  initializeConversation = message => {
    this.setState(
      {
        processTyping: this.receiveTyping,
        processMessage: this.receiveMessage,
      },
      () => this.getChatId(message),
    )
  }

  userInteractionPostMessage = data => {
    const { setFirstUnreadMessageId, botId, setIsEnableAriaLive } = this.props
    const { userData, chatWithAgent } = this.state

    this.props.updateBrowserStorage({ ref: null })
    setFirstUnreadMessageId(null)
    setIsEnableAriaLive(true)

    if (data.type === 'postback' && chatWithAgent) {
      sendAction(END_CONVERSATION, botId, userData.chatId)
        .then(() => this.postMessage(data))
        .then(() => this.finishChatWithAgent())
        .catch(error => console.log(error))
    } else {
      this.postMessage(data)
    }
  }

  receiveTyping = () => {
    if (!this.state.showTyping) {
      this.setState({ showTyping: true })
    }
  }

  receiveMessage = message => {
    this.props.updateMessages(message)
    this.setState({ showTyping: false })
  }

  updateTyping = showTyping => {
    this.setState({ showTyping })
  }

  sendStatus = (status, messageId) => {
    const { userData } = this.state
    const { botId, websiteLocation } = this.props
    this.websocketClient.sendStatus(status, messageId, botId, userData.chatId, websiteLocation)
  }

  finishChatWithAgent = () => {
    this.setState({ chatWithAgent: false })
  }

  render() {
    return (
      <WebSocketsSharedContext.Provider
        value={{
          postMessage: this.userInteractionPostMessage,
          startData: this.state?.userData,
          chatId: this.state.userData?.chatId,
          showTyping: this.state.showTyping,
          botId: this.props.botId,
          initializeConversation: this.initializeConversation,
          getMoreChatHistory: this.getMoreChatHistory,
          addReactionToCurrentMessages: this.addReactionToCurrentMessages,
          updateTyping: this.updateTyping,
          sendStatus: this.sendStatus,
          finishChatWithAgent: this.finishChatWithAgent,
        }}>
        {this.props.children}
      </WebSocketsSharedContext.Provider>
    )
  }
}

WebSockets.propTypes = {
  user: PropTypes.object,
  onConnect: PropTypes.func,
}

export default withRouter(WebSockets)
