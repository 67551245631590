import React, { useState, useEffect, useMemo, useContext } from 'react'
import useSound from 'use-sound'
import { BrowserStorageContext } from './BrowserStorageContext'

// @ts-ignore
import notification from 'static/notification.mp3'

interface Props {
  isSoundNotification: boolean
}

const CountUnreadMessagesContext = React.createContext({
  unreadMessages: 0,
  setUnreadMessages: undefined,
})

const CountUnreadMessagesProvider: React.FC<Props> = ({
  isSoundNotification,
  children,
}) => {
  const { updateBrowserStorage, browserStorage } = useContext(BrowserStorageContext)
  const [unreadMessages, setUnreadMessages] = useState(parseInt(browserStorage?.unreadMessages) || 0)
  const [play, { stop }] = useSound(notification)

  useEffect(() => {
    try {
      stop()
      if (unreadMessages && isSoundNotification) {
        play()
      }
    } catch (e) {
      console.log(e)
    }
  }, [unreadMessages])

  const handleUpdateMessages = unreadMessages => {
    updateBrowserStorage({ unreadMessages })
    setUnreadMessages(unreadMessages)
  }

  const value = useMemo(() => ({ unreadMessages, setUnreadMessages: handleUpdateMessages }), [unreadMessages])

  return <CountUnreadMessagesContext.Provider value={value}>{children}</CountUnreadMessagesContext.Provider>
}

export { CountUnreadMessagesProvider, CountUnreadMessagesContext }
