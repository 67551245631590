import React from 'react'
import './Typing.css'

export function Typing(props) {
  return (
    <div className="chat__bubble chat__bubble--rcvd chat__bubble-typing" data-autotest="typing">
      <div className="loading">
        <div className="dot one"></div>
        <div className="dot two"></div>
        <div className="dot three"></div>
      </div>
    </div>
  )
}
