import styled from 'styled-components'

const QuickReplyMultiSelectList = styled.div<{ showReplies: boolean; showAnimation: boolean, isLarge: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2px;
  margin: 8px 50px 4px 50px;
  visibility: ${({ showReplies }) => !showReplies && 'hidden'};
  animation: ${({ showAnimation }) => showAnimation && '.7s fadeOut'};
  flex-direction: ${({ isLarge }) => isLarge && 'column'};
`

export { QuickReplyMultiSelectList }
