import React, { useRef } from 'react'
import TemplateImg from '../TemplateImg'
import { generatePostBackObject } from 'actions/static'
import TemplateText from '../TemplateMessage/GenericTemplate/TemplateText'
import { usePreventFocus } from '../../../hooks/usePreventFocus'
import '../TemplateMessage/GenericTemplate/TemplateMessage.css'
import { DeviceType } from 'models/DeviceType'
import { SettingsType } from 'models/SettingsType'
import * as S from './TemplateSlide.style'
import { MessageType, ReactionType } from 'models/MessageType'
import MessageButton from '../MessageButton'

interface Props {
  element: any
  isRtl: boolean
  device: DeviceType
  settings: SettingsType
  postMessage: (m: MessageType) => void
  index: number
  isFocusButton: boolean
  enableReaction: boolean
  addReaction: (e: any, reactionValue: string, messageId: string) => void
  isMobile: boolean
  messageId: string
  reaction: ReactionType
}

const TemplateSlide: React.FC<Props> = ({
  index,
  element,
  isRtl,
  device,
  settings,
  postMessage,
  isFocusButton,
  enableReaction,
}) => {
  const slideRef = useRef(null)
  const preventFocus = usePreventFocus()

  const handleKeyUp = e => {
    const code = e.keyCode || e.which

    if (code == 9) slideRef.current.scrollIntoView()
  }

  return (
    <S.TemplateSlide
      data-autotest={'generic-template'}
      ref={slideRef}
      tabIndex={preventFocus ? -1 : 0}
      aria-label={`slide ${index + 1}`}
      onKeyUp={handleKeyUp}
      enableReaction={enableReaction}>
      <TemplateImg device={device} url={element.imageUrl} isTitle={element.title || element.subtitle} />
      <TemplateText device={device} isRtl={isRtl} title={element.title} subtitle={element.subtitle} />

      <S.ButtonsWrap>
        {element?.buttons?.map((button, i) => (
          <MessageButton
            button={button}
            key={i}
            title={button.title}
            color={settings.color}
            dataAutotest={`template-${index}-button-${i}`}
            onClick={() => postMessage(generatePostBackObject(button))}
            isFocusButton={isFocusButton && i === 0}
          />
        ))}
      </S.ButtonsWrap>
    </S.TemplateSlide>
  )
}

export default TemplateSlide
