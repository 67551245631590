import React from 'react'
import { DeviceType } from 'models/DeviceType'
import { usePreventFocus } from 'hooks/usePreventFocus'
import imgNotFound from 'icons/imgNotFound.png'

import * as S from './TemplateImg.style'

interface Props {
  url: string
  isTitle: boolean
  device: DeviceType
}

export const TemplateImg: React.FC<Props> = props => {
  const preventFocus = usePreventFocus()
  const imageName = props.url?.substring(props.url?.lastIndexOf('/') + 1)
  const imageUrl = props.url?.replace('(', '%28')?.replace(')', '%29')

  return (
    <S.TemplateImg
      aria-label={`image ${imageName}`}
      tabIndex={preventFocus ? -1 : 0}
      style={{ backgroundImage: `url(${imageUrl || imgNotFound})` }}
      {...props}
    />
  )
}
