import React, { useEffect, useContext } from 'react'

import MenuItem from 'components/MenuItem'
import xIcon from 'icons/x.png'
import { LineIcon } from 'icons/Icons'
import { generatePostBackObject } from 'actions/static'
import { DeviceType } from 'models/DeviceType'

import * as S from './PersistentMenu.style'
import { SettingsType } from 'models/SettingsType'
import { PersistentMenuType } from 'models/PersistentMenuType'
import { PersistentMenuContext } from 'context/PersistentMenuContext'
import { IframeContext } from 'context/IframeContext'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { usePreventFocusExceptMenu } from 'hooks/usePreventFocusExceptMenu'
import { CADET_BLUE } from 'constants/colors'

interface Props {
  device: DeviceType
  isFullScreenWidget: boolean
  settings: SettingsType
  persistentMenu: PersistentMenuType
  postMessage: (m: any) => void
  screenSize: DeviceType
  disableInput: boolean
}

export const PersistentMenu: React.FC<Props> = ({
  device,
  isFullScreenWidget,
  settings,
  persistentMenu,
  postMessage,
  screenSize,
  disableInput,
}) => {
  const { showPersistent, setShowPersistent } = useContext(PersistentMenuContext)
  const { showIframe } = useContext(IframeContext)
  const preventFocus = usePreventFocus()
  const preventFocusExceptMenu = usePreventFocusExceptMenu()

  useEffect(() => {
    setShowPersistent(false)
  }, [showIframe])

  const itemAction = item => {
    setShowPersistent(false)
    postMessage({ ...generatePostBackObject(item) })
  }

  return (
    <S.PersistentMenu data-testid="persistent-menu">
      <S.Hamburger
        id="menu-button"
        disabled={disableInput}
        onClick={() => setShowPersistent(true)}
        aria-label="open menu"
        tabIndex={preventFocus ? -1 : 0}
        data-testid="open-button">
        <LineIcon color={disableInput ? CADET_BLUE : settings.color} />
        <LineIcon color={disableInput ? CADET_BLUE : settings.color} />
        <LineIcon color={disableInput ? CADET_BLUE : settings.color} />
      </S.Hamburger>
      {!!persistentMenu?.callToActions?.length && showPersistent && (
        <S.Popup isFullScreenWidget={isFullScreenWidget} device={device} screenSize={screenSize}>
          <S.TitleWrap color={settings.color}>
            <S.Title tabIndex={preventFocusExceptMenu ? -1 : 0}>{persistentMenu?.title || 'Menu'}</S.Title>
            <S.CloseButton onClick={() => setShowPersistent(false)} tabIndex={preventFocusExceptMenu ? -1 : 0}>
              <img src={xIcon} alt="close menu" />
            </S.CloseButton>
          </S.TitleWrap>
          <S.MenuItemsWrap>
            {persistentMenu.callToActions.map((item, index) => (
              <MenuItem onClick={() => itemAction(item)} item={item} key={index} />
            ))}
          </S.MenuItemsWrap>
        </S.Popup>
      )}
    </S.PersistentMenu>
  )
}
