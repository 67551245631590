import React from 'react'
import classes from './styles.module.scss'

import { MaxiMazeIcon, MiniMazeIcon } from '../../icons/Icons'

interface Props {
  isLarge: boolean
}

const ResizeButton: React.FC<Props> = ({ isLarge }) => (
  <div className={classes.wrapper}>
    {isLarge ? <MiniMazeIcon /> : <MaxiMazeIcon />}
  </div>
)

export default ResizeButton
