import React, { useContext, useEffect, useMemo } from 'react'
import MessageBody from '../MessageBody'

import { checkShowLogo, defineMessageBorder } from '../../containers/Chat/config'
import { AriaLiveContext } from 'context/AriaLiveContext'
import { ScrollToButtonContext } from 'context/ScrollToButtonContext'
import { usePrevious } from 'hooks/usePrevious'
import { usePreventFocus } from 'hooks/usePreventFocus'

import * as S from './MessageGroup.style'
import _ from 'lodash'

const MessageGroup = ({
  group,
  device,
  parentURL,
  settings,
  isMobile,
  isFullScreenWidget,
  isLastGroup,
  showReply,
  firstUnreadMessageId,
  firstUnreadMessageRef,
  postMessage,
  setIsClickedOnReaction,
}) => {
  const prevGroupLength = usePrevious(group?.length)
  const { isEnableAriaLive } = useContext(AriaLiveContext)
  const { scrollToBottom } = useContext(ScrollToButtonContext)
  const preventFocus = usePreventFocus()

  useEffect(() => {
    if (isLastGroup && group?.length - prevGroupLength === 1) {
      scrollToBottom()
    }
  }, [group.length])

  const lastReactionElementIndex = useMemo(() => {
    let lastIndex
    group.some((val, index, array) => {
      const reverseIndex = array.length - 1 - index
      const enableReaction = _.get(array, `${reverseIndex}.enableReaction`)
      return enableReaction && (lastIndex = reverseIndex)
    })
    return lastIndex
  }, [group])

  return (
    <S.MessageGroup
      aria-live={isEnableAriaLive && group[0].isFromBot && isLastGroup && !preventFocus ? 'assertive' : 'off'}
      aria-atomic="true">
      {group[0].isFromBot && <div aria-label={'Bot says:'} />}
      {group?.map((message, msgIndex) => (
        <MessageBody
          key={message.id || msgIndex}
          device={device}
          message={message}
          parentURL={parentURL}
          settings={settings}
          isMobile={isMobile}
          isFullScreenWidget={isFullScreenWidget}
          showReply={showReply && msgIndex + 1 === group.length}
          showLogo={checkShowLogo(group, msgIndex)}
          messageBorder={defineMessageBorder(group, msgIndex)}
          postMessage={msg => postMessage(msg)}
          firstUnreadMessageId={firstUnreadMessageId}
          firstUnreadMessageRef={firstUnreadMessageRef}
          isLastMessage={isLastGroup && group.length === msgIndex + 1}
          setIsClickedOnReaction={setIsClickedOnReaction}
          showReactionElementText={msgIndex === lastReactionElementIndex}
        />
      ))}
    </S.MessageGroup>
  )
}

export default MessageGroup
