import styled from 'styled-components'

const TemplateImg = styled.div`
  max-width: 100%;
  height: 140px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: ${({ isTitle }) => (isTitle ? '10px 10px 0px 0px' : '10px 10px 10px 10px')};
  max-height: ${({ device }) => `${device?.width ? device.width * 0.45 : 140}px`};
`

export { TemplateImg }
