import React, { useMemo, useState } from 'react'
import QuickReply from '../QuickReply'
import GeoReply from '../GeoReply'

import * as S from './QuickReplyList.style'
import { hasAttributeValue } from 'helper/hasAttributeValue'
import { IS_BUTTON_LARGE } from 'constants/replyAttributes'

interface Props {
  message: any
  color: string
  isFocusReply: boolean
  postMessage: (m: any) => void
}

export const QuickReplyList: React.FC<Props> = ({ message, color, postMessage, isFocusReply }) => {
  const [showReplies, setShowReplies] = useState(true)
  const [showAnimation, setShowAnimation] = useState(false)

  const isLarge = useMemo(() => {
    return message?.quickReplies?.some(reply => hasAttributeValue(reply, IS_BUTTON_LARGE, 'true'))
  }, [])

  const handlePost = message => {
    setShowAnimation(true)

    setTimeout(() => {
      setShowReplies(false)
      postMessage(message)
    }, 700)
  }

  return (
    <S.QuickReplyList showReplies={showReplies} showAnimation={showAnimation} isLarge={isLarge}>
      {message?.quickReplies?.map((reply, index) => (
        <div key={index}>
          {reply.type && reply.type === 'text' ? (
            <QuickReply
              postMessage={handlePost}
              color={color}
              reply={reply}
              isFocusReply={isFocusReply && index === 0}
              isLarge={isLarge}
            />
          ) : (
            <GeoReply postMessage={handlePost} />
          )}
        </div>
      ))}
    </S.QuickReplyList>
  )
}
