import React, { useContext, useEffect } from 'react'

import launcherIcon from 'icons/launcher.png'
import xIcon from 'icons/x.png'
import { UnreadMessagesCounter } from '../UnreadMessagesCounter'
import { CountUnreadMessagesContext } from 'context/CountUnreadMessagesContext'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { UnreadMessagesContext } from 'context/UnreadMessagesContext'
import { AriaLiveContext } from 'context/AriaLiveContext'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import {
  sendGreetingEvent
} from 'api/api'
import { SettingsType } from 'models/SettingsType'
import { MessageType } from 'models/MessageType'

import * as S from './Button.style'
import { BrowserStorageContext } from '../../context/BrowserStorageContext'

interface Props {
  isWidgetOpened: boolean
  settings: SettingsType
  toggleWidget: () => void
  greeting: any
  authorized: boolean
  botId: number
  isHiddenWidget: boolean
  handleViewWidget: () => void
  messages: MessageType[]
  isButtonNeeded: boolean
  popupWasShow: boolean
  updateEventId?: any
  eventId?: number
}

export const Button: React.FC<Props> = ({
  isWidgetOpened,
  settings,
  toggleWidget,
  greeting,
  authorized,
  botId,
  isHiddenWidget,
  handleViewWidget,
  messages,
  isButtonNeeded,
  popupWasShow,
  updateEventId,
  eventId
}) => {
  const { initializeConversation }: any = useContext(WebSocketsSharedContext)
  const { unreadMessages } = useContext(CountUnreadMessagesContext)
  const { setIsOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { isWidgetPopup } = useContext(UnreadMessagesContext)
  const { setIsEnableAriaLive } = useContext(AriaLiveContext)
  const { browserStorage } = useContext(BrowserStorageContext)

  const listenerToggleWidget = event => {
    if (event.data.type === 'open-widget') {
      handleToggleWidget()
    }
  }

  useEffect(() => {
    window.addEventListener('message', listenerToggleWidget)
    return () => {
      window.removeEventListener('message', listenerToggleWidget)
    }
  }, [listenerToggleWidget])

  const handleToggleWidget = () => {
    const chatId = browserStorage.chatId

    if (greeting && popupWasShow && !eventId) {
      sendGreetingEvent('OPENED', botId, greeting.hash, chatId)
        .then((data) => updateEventId(data?.id))
        .then(() => {
          handleInitializeConversation()
          handleOpenWidget()
        })
    } else {
      handleInitializeConversation()
      handleOpenWidget()
    }

    if (messages.length) {
      setIsEnableAriaLive(false)
    }
  }

  const handleInitializeConversation = () => {
    const isNoStartScreen = !settings.isLoginNameInputEnabled && !settings.isLoginEmailInputEnabled

    if ((isWidgetPopup || popupWasShow) && greeting && greeting.payload && authorized) {
      const message = {
        type: 'postback',
        payload: greeting.payload,
        isRtl: false,
      }
      initializeConversation(message)
    } else if (!messages.length && isNoStartScreen) {
      initializeConversation()
    }
  }

  const handleOpenWidget = () => {
    const chatId = browserStorage.chatId

    if (isHiddenWidget) {
      handleViewWidget()
    } else if (!chatId) {
      toggleWidget()
    } else if (!isWidgetOpened) {
      setIsOpenEndConversationModal(true)
    } else if (isWidgetOpened) {
      toggleWidget()
    }
  }

  return (
    <S.Container data-testid="button" isButtonNeeded={isButtonNeeded}>
      <S.Button color={settings?.color} onClick={handleToggleWidget} id="widget-button">
        {isWidgetOpened ? (
          <S.Logo src={launcherIcon} alt="open widget" />
        ) : (
          <S.Icon src={xIcon} alt="close widget" id="close-widget-icon" />
        )}
      </S.Button>
      {isHiddenWidget && !!unreadMessages && <UnreadMessagesCounter />}
    </S.Container>
  )
}
