import styled from 'styled-components'
import { WHITE, RIBBON_BLUE } from 'constants/colors'

const QuickReply = styled.button<{ color: string, isLarge: boolean }>`
  animation: ${({ isAnimation }) => isAnimation && '0.5s messageAnimation'};
  margin: 2px 0px 3px 5px;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  background: ${WHITE};
  border: 1px solid ${({ color }) => color || RIBBON_BLUE};
  color: ${({ color }) => color || RIBBON_BLUE};
  width: ${({ isLarge }) => isLarge && '-webkit-fill-available'};
  box-sizing: border-box;
  border-radius: 26px;
  line-break: loose;
  user-select: none;
  line-height: 1.2;
  outline: none;
  word-break: break-word;
`

export { QuickReply }
