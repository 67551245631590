import styled, { css } from 'styled-components'
import {ATHENS_GREY, HAWKES_BLUE, PALE_SKY, WHITE} from 'constants/colors'

const showComponent = (isPickedReaction, showElement) => {
  return isPickedReaction ? 'flex' : (showElement ? 'flex' : 'none')
}

export const ReactionWrapper = styled.div.attrs(() => ({
  'data-id': 'reaction-bar'
}))`
  display: ${props => showComponent(props.isPickedReaction, props.showElement)};
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${props => (props.isPickedReaction ? -18 : -29)}px;
  right: 30px;
  
  ${props => ((props.isAttachment && props.isPickedReaction) && css`
    cursor: auto;
    pointer-events: none;
  `)}
`

export const ReactionContainer = styled.div`
  background-color: ${WHITE};
  box-shadow: 0 3px 6px ${ATHENS_GREY};
  border-radius: 24px;
`

export const ReactionText = styled.p`
  color: ${PALE_SKY};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0 8px 0 0;
`

export const ReactionButton = styled.button`
  background-color: ${WHITE};
  border: none;
  padding: 0;
  border-radius: 50%;
  width: ${props => (props.isMobile ? 40 : 32)}px;
  height: ${props => (props.isMobile ? 40 : 32)}px;;
  
  &:hover {
    cursor: pointer;
    background-color: ${HAWKES_BLUE};
  }
`

export const ReactionIconContainer = styled.span`
  font-size: 14px;
  height: 16px;
  width: 16px;
  user-select: none;
`
