import React, { useState } from 'react'

const PersistentMenuContext = React.createContext({
  showPersistent: false,
  setShowPersistent: undefined,
})

const PersistentMenuProvider = props => {
  const [showPersistent, setShowPersistent] = useState(false)

  const value = React.useMemo(
    () => ({
      showPersistent,
      setShowPersistent,
    }),
    [showPersistent],
  )

  return <PersistentMenuContext.Provider value={value}>{props.children}</PersistentMenuContext.Provider>
}

export { PersistentMenuProvider, PersistentMenuContext }
