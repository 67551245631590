import { API_URL } from '../config/config'
import { createJsonHeaders, logoutOnRequestOrResponseJson } from './api'

export const getChatIdRequest = data => {
  return fetch(`${API_URL}/sockwidget/getStarted`, {
    method: 'POST',
    headers: createJsonHeaders(),
    body: JSON.stringify(data),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => response.data)
    .catch(err => console.log(err))
}

export const getChatHistoryRequest = (chatId, botId, size, page = 0) => {
  return fetch(
    `${API_URL}/widget/${botId}/chat/${chatId}?size=${size}&page=${page}&sort=id,desc`,
    {
      method: 'GET',
      headers: createJsonHeaders(),
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => {
      return response?.data?.content
    })
    .catch(err => console.log(err))
}
