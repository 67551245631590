import { MutableRefObject, useEffect } from 'react'

// This is required to scroll down because we can have unfinished smooth scroll after opening widget
const scrollDelay = 250

type scrollWidgetParams = {
  isHiddenWidget: boolean
  isProcessingGPTMessage: boolean
  isGPTMessagesQueueNotEmpty: boolean
  allowAutoScroll: MutableRefObject<boolean>
  scrollToBottom: (behavior?: string) => void
}
export const useScrollOnOpenWidget = ({
  isHiddenWidget,
  isProcessingGPTMessage,
  isGPTMessagesQueueNotEmpty,
  allowAutoScroll,
  scrollToBottom,
}: scrollWidgetParams): void => {
  useEffect(() => {
    if (!isHiddenWidget && isProcessingGPTMessage && isGPTMessagesQueueNotEmpty) {
      const timeoutId = setTimeout(() => {
        scrollToBottom('auto')
        allowAutoScroll.current = true
      }, scrollDelay)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isHiddenWidget])
}
