import { BotPersonality } from '../models/SettingsType.d'

const defaultCustomerSupportImage = `${process.env.PUBLIC_URL}/images/default-user-icon.svg`

const getImageFromCustomerSupport = customerSupportDetails => {
  return customerSupportDetails.imageUrl || defaultCustomerSupportImage
}

export const getMessageLogo = (message, settings) => {
  if (message.botPersonality && message.botPersonality === BotPersonality.COMPANION) {
    return settings.logo
  } else if (message.botPersonality && message.botPersonality === BotPersonality.EXPERT) {
    return settings.expertBotLogo
  }

  return message?.customerSupportDetails
    ? getImageFromCustomerSupport(message.customerSupportDetails)
    : settings.logo
}
