export const styleByScreenSize = (
  isFullScreenWidget,
  width,
  height,
  isMobile,
  screenSize
) => {
  if (isFullScreenWidget) {
    return {
      width: '100%',
      height: '100%',
      margin: 0,
      boxShadow: '0px -6px 16px rgba(19, 69, 186, 0.096)',
    }
  }

  if (isMobile) {
    return {
      width: width,
      height: height,
    }
  }

  return {
    width: '360px',
    height: '536px',
    margin: '12px 12px 20px',
    boxShadow: '0px -6px 16px rgba(19, 69, 186, 0.096)',
    maxHeight: screenSize?.height - 120
  }
}
