export type SettingsType = {
  endConversation: {
    title: string
    subtitle: string
  }
  failedMessage: {
    title: string
  }
  promptButtons: {
    cancelTitle: string
    endTitle: string
    sendTitle: string
    tryAgainTitle: string
  }
  color: string
  rating: {
    title: string
    commentInputPlaceholder: string
  }
  widgetActions: {
    maximizeWidgetTitle: string
    minimizeWidgetTitle: string
    soundOffTitle: string
    soundOnTitle: string
    endConversationTitle: string
  }
  unreadMessagesTitle: string
  widgetSettings: {
    doShowPopupMessagePreview
    doEnableSoundNotifications
    doAskForRating
  }
  logo: string
  expertBotLogo: string
  widgetName: string
  hasPersistentMenu: boolean
  statusesLabels: {
    agentJoined: string
    agentLeft: string
    userEnded: string
    sessionExpired: string
  }
  isLoginEmailInputEnabled: boolean
  isLoginNameInputEnabled: boolean
  isRtl: boolean
  nameInputError: string
  emailInputEmptyError: string
  emailInputError: string
  startLabel: string
  nameInputPlaceholder: string
  emailInputPlaceholder: string
  startButton: string
  inputPlaceholder: string
}

export enum BotPersonality {
  // eslint-disable-next-line no-unused-vars
  COMPANION = 'COMPANION',
  // eslint-disable-next-line no-unused-vars
  EXPERT = 'EXPERT'
}
