import React from 'react'

export default function CenteredLoader(props) {
  return (
    <div
      style={{
        position: 'absolute',
        left: `calc(50% - ${props.size / 2}px)`,
        top: `calc(50% - ${props.size / 2}px)`,
      }}>
      <div
        className="loader"
        style={{
          borderColor: props.color,
          borderTopColor: '#f3f3f3',
        }}
      />
    </div>
  )
}
