import React, { useContext, useEffect, useMemo, useRef } from 'react'
import * as ReactDOM from 'react-dom'
import { uid } from 'react-uid/dist/es5/index'
import xss from 'xss'

import { generatePostBackObject } from 'actions/static'
import { generateNewTabName } from 'helper/generateNewTabName'
import { wrapTextAddLinks } from 'helper/wrapTextAddLinks'
import { SettingsType } from 'models/SettingsType'
import { ATHENS_GREY } from 'constants/colors'
import classes from './styles.module.scss'
import { DeviceType } from 'models/DeviceType'
import { MessageType } from 'models/MessageType'
import { IframeContext } from 'context/IframeContext'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { xssOptions } from 'config/xssOptions'
import MessageButton from '../MessageButton'

const messageWidthBtn = screenWidth =>
  ({
    width: screenWidth ? (screenWidth * 70) / 100 + 'px' : '254px',
    whiteSpace: 'pre-wrap',
  } as React.CSSProperties)

export const messageWidthText = (screenWidth, color, messageBorder, isRtl) =>
  ({
    maxWidth: screenWidth ? (screenWidth * 70) / 100 + 'px' : '254px',
    whiteSpace: 'pre-wrap',
    backgroundColor: color ? color : '',
    borderRadius: messageBorder,
    direction: isRtl ? 'rtl' : 'ltr',
  } as React.CSSProperties)

interface Props {
  postMessage: (a: any) => void
  message: MessageType
  device: DeviceType
  settings: SettingsType
  messageBorder: string
  showLogo: boolean
  isFocusButton: boolean
  enableReaction: boolean
  addReaction: (e: any, reactionValue: string, messageId: string) => void
  isMobile: boolean
}

const enterKeyCode = 13

const TextMessage: React.FC<Props> = ({
  postMessage,
  message,
  device,
  settings,
  messageBorder,
  showLogo,
  isFocusButton,
}) => {
  const { handleOpenIframe } = useContext(IframeContext)
  const output = useRef(null)
  const color = useMemo(() => (message.isFromBot ? ATHENS_GREY : settings.color), [])
  const bubbleClass = useMemo(() => (message.isFromBot ? classes.bubbleReceived : classes.bubbleSent), [])
  const preventFocus = usePreventFocus()

  useEffect(() => {
    linkEditorListener()
  }, [preventFocus])

  const linkEditorListener = () => {
    // @ts-ignore
    const node = ReactDOM.findDOMNode(output.current).querySelectorAll('.linkEdited')
    const links = [...node]

    links.length > 0 &&
      links.forEach(link => {
        const extension = JSON.parse(link.getAttribute('data-extension'))
        const url = link.getAttribute('data-href')

        link.tabIndex = preventFocus ? -1 : 0
        link.ariaLabel = `${link.innerText} - link`

        if (extension) {
          link.onclick = () => handleOpenIframe(url)
          link.onkeypress = e => handleEnterLink(e, () => handleOpenIframe(url))
        } else {
          link.onclick = () => openNewTab(url)
          link.onkeypress = e => handleEnterLink(e, () => openNewTab(url))
        }
      })
  }

  const handleEnterLink = (e, callback) => {
    const keycode = e.keyCode || e.which

    if (keycode === enterKeyCode) {
      callback()
    }
  }

  const openNewTab = url => {
    window.open(url, generateNewTabName())
  }

  return (
    <span ref={output} data-autotest="text-message-container">
      {message?.buttons?.length ? (
        <div data-autotest="text-message-buttons" className={classes.container} style={messageWidthBtn(device.width)}>
          <div style={messageWidthBtn(device.width)} className={classes.button}>
            <span
              tabIndex={preventFocus ? -1 : 0}
              className={`${bubbleClass} ${classes.bubble}`}
              style={{ direction: message.isRtl ? 'rtl' : 'ltr' }}
              dangerouslySetInnerHTML={{ __html: xss(wrapTextAddLinks(message.text, preventFocus), xssOptions) }}
              data-autotest={'text-message'}
            />
          </div>
          {message.buttons.map((button, index) => (
            <MessageButton
              dataAutotest="template-button"
              button={button}
              key={uid(button)}
              title={button.title}
              color={settings.color}
              onClick={() => postMessage(generatePostBackObject(button))}
              isFocusButton={isFocusButton && showLogo && index === 0}
            />
          ))}
        </div>
      ) : (
        <span
          tabIndex={preventFocus ? -1 : 0}
          className={`${bubbleClass} ${classes.bubble}`}
          style={messageWidthText(device.width, color, messageBorder, message.isRtl)}
          dangerouslySetInnerHTML={{ __html: xss(wrapTextAddLinks(message.text, preventFocus), xssOptions) }}
          data-autotest="text-message"
        />
      )}
    </span>
  )
}

export default TextMessage
