import React, { useContext } from 'react'

import WebSockets from '../integrations/WebSockets'

import { CountUnreadMessagesContext } from './CountUnreadMessagesContext'
import { FeedbackModalContext } from './FeedbackModalContext'
import { UnreadMessagesContext } from './UnreadMessagesContext'
import { AriaLiveContext } from './AriaLiveContext'
import { BrowserStorageContext } from './BrowserStorageContext'

export const WebSocketsProviders = props => {
  const { unreadMessages, setUnreadMessages } = useContext(CountUnreadMessagesContext)
  const { setIsOpenFeedbackModal } = useContext(FeedbackModalContext)
  const { handleAddUnreadMessages, isWidgetPopup } = useContext(UnreadMessagesContext)
  const { setIsEnableAriaLive } = useContext(AriaLiveContext)
  const { updateBrowserStorage, browserStorage } = useContext(BrowserStorageContext)

  return (
    <WebSockets
      unreadMessages={unreadMessages}
      setUnreadMessages={setUnreadMessages}
      setIsEnableAriaLive={setIsEnableAriaLive}
      setIsOpenFeedbackModal={setIsOpenFeedbackModal}
      handleAddUnreadMessages={handleAddUnreadMessages}
      isWidgetPopup={isWidgetPopup}
      updateBrowserStorage={updateBrowserStorage}
      browserStorage={browserStorage}
      {...props}
    />
  )
}
