import { MessageType } from '../constants'
import { getPostbackMessage } from './getPostbackMessage'

export const getMessagesFromHistory = history => {
  return history
    .map(event => {
      let message = Object.assign({}, event.message)
      message.isFromBot = !!event.isFromBot
      message.status = event.status
      message.reaction = event.reaction
      if (message.type === MessageType.postback) {
        message = getPostbackMessage(message)
      }
      message.action = event.action
      message.botPersonality = event.botPersonality
      return message
    })
    .reverse()
}
