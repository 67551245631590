/*
 * @author oles.pankiv@botscrew.com
 * @date 13.11.2020
 *
 * WebSocketClient encapsulates logic for sending/receiving messages from BEP back-end.
 *
 * Functionality built in:
 *     - cache messages if connection not established and send them right away if successfully connected to the server
 *     - reconnect to server when connection lost
 *     - other minor stuff like JSON.parse() before sending the message to the callback function
 *
 */

import SockJS from 'sockjs-client'
import { Stomp } from '@stomp/stompjs'

import { API_URL } from '../config/config'
import { sendAction } from './api'
import { START_CONVERSATION } from '../constants/actionsType'

class WebSocketClient {
  _client
  _chatId
  _botId
  _onConnectionEstablishedMessages = []
  _onConnectionEstablishedStatus = []

  _onSuccessCallback

  _greetingsSubscription
  _languageSubscription

  _receiveMessageCallback
  _receiveLanguageUpdateCallback

  _createNewSocketConnection() {
    this._client = Stomp.over(this._createSockJs)
    this._client.reconnect_delay = 5000
    this._client.heartbeat.incoming = 10000
    this._client.heartbeat.outgoing = 10000
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._client.debug = () => {} //NOSONAR
  }

  _createSockJs = () => {
    return new SockJS(`${API_URL}/sockwidget`)
  }

  connect(botId, chatId, initiateConversation, updateParentSiteLocalStorage, isConversationInitialized) {
    this._createNewSocketConnection()
    this._client.connect({}, () =>
      // eslint-disable-next-line max-len
      this._onConnectionSuccess(
        botId,
        chatId,
        initiateConversation,
        updateParentSiteLocalStorage,
        isConversationInitialized,
      ),
    )
  }

  _onConnectionSuccess(botId, chatId, initiateConversation, updateParentSiteLocalStorage, isConversationInitialized) {
    if (this._client.connected) {
      this.subscribeForTopics()
      this.getStarted(botId, chatId, initiateConversation, updateParentSiteLocalStorage, isConversationInitialized)
      this.sendFailedMessages()
    }
  }

  sendFailedMessages() {
    this._onConnectionEstablishedMessages.forEach(message => this.sendMessage(message, this._botId, this._chatId))
    this._onConnectionEstablishedStatus.forEach(status => this.sendStatus(status, this._botId, this._chatId))
    this._onConnectionEstablishedMessages = []
    this._onConnectionEstablishedStatus = []
  }

  configureEventHandler(receiveMessageCallback) {
    this._receiveMessageCallback = receiveMessageCallback
  }

  configureLanguageUpdateHandler(callback) {
    this._receiveLanguageUpdateCallback = callback
  }

  configureChatId(chatId) {
    this._chatId = chatId
  }

  getStarted(botId, chatId, initiateConversation, updateParentSiteLocalStorage, isConversationInitialized) {
    if (!isConversationInitialized) {
      if (initiateConversation) {
        sendAction(START_CONVERSATION, botId, chatId)
      }
      updateParentSiteLocalStorage({ isConversationInitialized: true })
    }
  }

  configureBotId(botId) {
    this._botId = botId
  }

  subscribeForTopics() {
    if (this._chatId) {
      this._greetingsSubscription = this._client.subscribe(`/topic/messaging.${this._chatId}`, message =>
        this._onReceiveMessage(message),
      )
      this._languageSubscription = this._client.subscribe(`/topic/language-update.${this._chatId}`, message =>
        this._onReceiveLanguageMessage(message),
      )
    }
  }

  _onReceiveMessage(messageInJson) {
    const message = JSON.parse(messageInJson.body)
    this._receiveMessageCallback(message)
  }

  _onReceiveLanguageMessage(messageInJson) {
    const message = JSON.parse(messageInJson.body)
    this._receiveLanguageUpdateCallback(message)
  }

  sendMessage(message, botId, chatId) {
    if (this._client?.connected && chatId) {
      this._client.send('/app/widget/' + botId + '/' + chatId, {}, JSON.stringify(message))
    } else {
      const messageCopy = JSON.parse(JSON.stringify(message))
      this._onConnectionEstablishedMessages.push(messageCopy)
    }
  }

  sendStatus(status, messageId, botId, chatId, websiteLocation) {
    const statusObj = {
      status: {
        messageId,
        status,
      },
      chatId,
      websiteLocation,
    }
    if (messageId) {
      if (this._client?.connected && messageId && chatId) {
        this._client.send('/app/widget/' + botId + '/' + chatId, {}, JSON.stringify(statusObj))
      } else {
        const statusCopy = JSON.parse(JSON.stringify(status))
        this._onConnectionEstablishedStatus.push(statusCopy)
      }
    }
  }

  disconnect() {
    if (this._client?.connected) {
      this._greetingsSubscription.unsubscribe()
      this._languageSubscription.unsubscribe()
      this._client.disconnect()
    }
  }
}

export default WebSocketClient
