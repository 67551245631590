import React, { useContext } from 'react'
import * as S from './MenuItem.style'
import { usePreventFocusExceptMenu } from 'hooks/usePreventFocusExceptMenu'
import { IframeContext } from 'context/IframeContext'

interface Props {
  item: any
  onClick: () => void
}

export const MenuItem: React.FC<Props> = ({ item, onClick }) => {
  const { handleOpenIframe } = useContext(IframeContext)
  const preventFocus = usePreventFocusExceptMenu()

  const handleClick = () => {
    if (item.type === 'web_url') {
      if (item.enableMessengerExtensions) {
        handleOpenIframe(item.url)
      } else {
        const target = item?.url?.endsWith('openInWidgetPage=true') ? '_parent' : '_blank'
        window.open(item.url, target)
      }
    } else {
      onClick()
    }
  }

  return (
    <S.ItemButton
      data-autotest={'menu-item-button'}
      onClick={handleClick}
      data-testid="menu-item-button"
      tabIndex={preventFocus ? -1 : 0}>
      <span>{item.title}</span>
    </S.ItemButton>
  )
}
