type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S

export const MESSAGE_SIZE = 20
export const MAX_INPUT_LENGTH = 255

type MessageTypes = 'streamable_text' | 'text' | 'generic_template' | 'attachment' | 'postback'
export const MessageType: Record<SnakeToCamelCase<MessageTypes>, MessageTypes> = {
  streamableText: 'streamable_text',
  text: 'text',
  genericTemplate: 'generic_template',
  attachment: 'attachment',
  postback: 'postback',
}

type StreamActionTypes = 'START' | 'MESSAGE' | 'STOP' | 'STORED' | 'ERROR'
export const StreamActionType: Record<StreamActionTypes, StreamActionTypes> = {
  START: 'START',
  MESSAGE: 'MESSAGE',
  STOP: 'STOP',
  STORED: 'STORED',
  ERROR: 'ERROR',
}
