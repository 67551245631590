import React, { useMemo } from 'react'
import classes from './styles.module.scss'
import { ActionType } from 'models/MessageType'
import { SettingsType } from 'models/SettingsType'
import { AGENT_JOINED, AGENT_LEFT, END_CONVERSATION, SESSION_EXPIRED } from 'constants/actionsType'
import { usePreventFocus } from 'hooks/usePreventFocus'

interface Props {
  action: ActionType
  settings: SettingsType
}

export const ActionMessage: React.FC<Props> = ({ action, settings }) => {
  const preventFocus = usePreventFocus()

  const actionText = useMemo(() => {
    if (action.type === END_CONVERSATION) {
      return settings?.statusesLabels?.userEnded
    } else if (action.type === AGENT_LEFT) {
      return settings?.statusesLabels?.agentLeft
    } else if (action.type === AGENT_JOINED) {
      return settings?.statusesLabels?.agentJoined
    } else if (action.type === SESSION_EXPIRED) {
      return settings?.statusesLabels?.sessionExpired
    }
  }, [])
  return (
    <div className={classes.container} tabIndex={preventFocus ? -1 : 0}>
      {actionText}
    </div>
  )
}
