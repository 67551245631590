import React, { useMemo } from 'react'
import { SettingsType } from 'models/SettingsType'
import QuickReplyList from '../QuickReplyList'
import QuickReplyMultiSelectList from '../QuickReplyMultiSelectList'
import { MessageType } from 'models/MessageType'
import { hasAttributeValue } from 'helper/hasAttributeValue'
import { IS_MULTI_SELECT } from 'constants/replyAttributes'

interface QuickRepliesWrapperProps {
  message: MessageType
  settings: SettingsType
  postMessage?: (m: MessageType) => void
}

export const QuickRepliesWrapper: React.FC<QuickRepliesWrapperProps> = ({ message, settings, postMessage }) => {
  const isMultiSelect = useMemo(() => {
    return message?.quickReplies?.some(reply => hasAttributeValue(reply, IS_MULTI_SELECT, 'true'))
  }, [])

  return isMultiSelect ? (
    <QuickReplyMultiSelectList message={message} color={settings?.color} postMessage={postMessage} />
  ) : (
    <QuickReplyList
      message={message}
      color={settings?.color}
      postMessage={postMessage}
      isFocusReply={!message.buttons && !message?.elements?.some(elem => elem?.buttons?.length)}
    />
  )
}
