export const getReactionsTitle = (reactionValue: string): string => {
  let reactionTitle
  switch (reactionValue) {
    case 'thumbsup':
      reactionTitle = 'Positive reaction'
      break
    case 'thumbsdown':
      reactionTitle = 'Negative reaction'
      break
  }

  return reactionTitle
}
