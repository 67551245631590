import React, { useMemo } from 'react'
import classes from './styles.module.scss'
import { SettingsType } from 'models/SettingsType'
import { getMessageLogo } from 'helper/getMessageLogo'
import { MessageType } from 'models/MessageType'
import { SendStatusIcon, ReadStatusIcon } from 'icons/Icons'
import { READ } from 'constants/statuses'
import moment from 'moment'
import { MessageType as MsgType } from 'constants/index'
import { QuickRepliesWrapper } from '../QuickRepliesWrapper'

const getContainerClass = (message: MessageType, shouldShowBotLogo: boolean) => {
  const wrapClass = getWrapClass(shouldShowBotLogo)
  const senderClass = getSenderClass(message)

  if (message.type === MsgType.genericTemplate) {
    return `${wrapClass} ${classes.templateContainer}`
  }
  if (message.type === MsgType.text && !message.buttons) {
    return `${wrapClass} ${classes.textContainer} ${senderClass}`
  }
  return wrapClass
}

const getWrapClass = (shouldShowBotLogo: boolean) => {
  if (shouldShowBotLogo) {
    return classes.containerWithLogo
  }
  return classes.container
}

const getSenderClass = (message: MessageType) => (message?.isFromBot ? classes.receive : classes.sent)

interface Props {
  postMessage?: (m: MessageType) => void
  showReply: boolean
  showLogo: boolean
  isLastMessage: boolean
  message: MessageType
  settings: SettingsType
  showTimestamp?: boolean
}

export const MessageWrapper: React.FC<Props> = ({
  showReply,
  showLogo,
  message,
  settings,
  children,
  postMessage,
  isLastMessage,
  showTimestamp = true,
}) => {
  const formattedTimestamp = useMemo(() => {
    const localTime = moment.utc(message.timestamp).toDate()

    return moment(localTime).format('hh:mm')
  }, [])

  const isEmptyTextMessage = message.type === 'text' && !message.text
  const shouldShowBotLogo = message && message.isFromBot && showLogo

  return isEmptyTextMessage ? (
    <div></div>
  ) : (
    <>
      <div
        className={getContainerClass(message, shouldShowBotLogo)}
        data-autotest={`${isLastMessage ? 'last-message ' : ''}${
          message?.isFromBot ? 'message-received' : 'message-send'
        }`}>
        {shouldShowBotLogo && (
          <img className={classes.logo} src={getMessageLogo(message, settings)} alt="icon" aria-hidden="true" />
        )}
        <>
          {children}
          {children && (
            <div className={message?.isFromBot ? classes.statusWrapSent : classes.statusWrapReceive} aria-hidden="true">
              <div>{showTimestamp && formattedTimestamp}</div>
              {!message?.isFromBot && <StatusIcon status={message.status} />}
            </div>
          )}
        </>
      </div>

      {showReply && <QuickRepliesWrapper message={message} settings={settings} postMessage={postMessage} />}
    </>
  )
}

const StatusIcon = status => {
  return status === READ ? <ReadStatusIcon /> : <SendStatusIcon />
}
