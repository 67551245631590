import { useContext } from 'react'

import { IframeContext } from 'context/IframeContext'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { FeedbackModalContext } from 'context/FeedbackModalContext'

export const usePreventFocusExceptMenu = () => {
  const { showIframe } = useContext(IframeContext)
  const { isOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { isOpenFeedbackModal } = useContext(FeedbackModalContext)

  return isOpenEndConversationModal || isOpenFeedbackModal || showIframe
}
