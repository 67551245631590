import React from 'react'
import * as S from './ModalButton.style'

interface Props {
  type: 'cancel' | 'submit'
  color?: string
  title: string
  dataTestId?: string
  id?: string
  onClick: () => void
}

export const ModalButton: React.FC<Props> = ({ title, id, onClick, color, type, dataTestId }) => {
  return (
    <S.ModalButton color={color} type={type} id={id} onClick={onClick} tabIndex={1} data-testid={dataTestId}>
      {title}
    </S.ModalButton>
  )
}
