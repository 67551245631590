import styled from 'styled-components'
import { OXFORD_BLUE } from 'constants/colors'

const Title = styled.p`
  margin: 0px 0px 12px 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${OXFORD_BLUE};
  text-align: center;
  letter-spacing: -0.02em;
`

const SubTitle = styled.p`
  margin: 0px 0px 24px 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(53, 64, 82, 0.7);
  text-align: center;
  letter-spacing: -0.01em;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`

export { Title, SubTitle, ButtonsWrap }
