import { useContext } from 'react'

import { IframeContext } from 'context/IframeContext'
import { PersistentMenuContext } from 'context/PersistentMenuContext'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { FeedbackModalContext } from 'context/FeedbackModalContext'

export const usePreventFocus = () => {
  const { showIframe } = useContext(IframeContext)
  const { showPersistent } = useContext(PersistentMenuContext)
  const { isOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { isOpenFeedbackModal } = useContext(FeedbackModalContext)

  return showPersistent || isOpenEndConversationModal || isOpenFeedbackModal || showIframe
}
