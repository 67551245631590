import React, { useState } from 'react'

import { deepCopy } from '../helper/deepCopy'

const UnreadMessagesContext = React.createContext({
  unreadMessagesList: [],
  handleAddUnreadMessages: undefined,
  handleDeleteUnreadMessages: undefined,
  handleClearUnreadMessages: undefined,
  isWidgetPopup: false,
})

const UnreadMessagesProvider = ({ children }) => {
  const [unreadMessagesList, setUnreadMessagesList] = useState([])

  const handleAddUnreadMessages = (newUnreadMessage, websiteLocation) => {
    const unreadMessagesListCopy = deepCopy(unreadMessagesList)
    unreadMessagesListCopy.push(newUnreadMessage)
    const lastTwoElem = unreadMessagesListCopy.slice(unreadMessagesListCopy.length - 5, unreadMessagesListCopy.length)
    updateUnreadMessagesList(lastTwoElem, websiteLocation)
  }

  const handleDeleteUnreadMessages = (deleteId, websiteLocation) => {
    const updatedUnreadMessagesList = unreadMessagesList.filter(({ id }) => id !== deleteId)
    updateUnreadMessagesList(updatedUnreadMessagesList, websiteLocation)
  }

  const handleClearUnreadMessages = websiteLocation => {
    updateUnreadMessagesList([], websiteLocation)
  }

  const updateUnreadMessagesList = (unreadMessagesList, websiteLocation) => {
    setUnreadMessagesList(unreadMessagesList)
    postMessageUpdateGreeting(unreadMessagesList, websiteLocation)
  }

  const getIsWidgetPopup = () => {
    return unreadMessagesList.some(message => message.isWidgetPopup)
  }

  const postMessageUpdateGreeting = (greetings, websiteLocation) => {
    window.parent.postMessage(
      {
        type: 'update-greeting',
        greetings,
      },
      websiteLocation,
    )
  }

  return (
    <UnreadMessagesContext.Provider
      value={{
        isWidgetPopup: getIsWidgetPopup(),
        unreadMessagesList,
        handleClearUnreadMessages,
        handleAddUnreadMessages,
        handleDeleteUnreadMessages,
      }}>
      {children}
    </UnreadMessagesContext.Provider>
  )
}

export { UnreadMessagesProvider, UnreadMessagesContext }
