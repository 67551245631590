import React, { useEffect, useRef, useState } from 'react'
import { generatePostBackObject } from 'actions/static'
import * as S from './QuickReply.style'
import { usePreventFocus } from 'hooks/usePreventFocus'

interface Props {
  reply: any
  color: string
  isFocusReply: boolean
  isLarge: boolean
  postMessage: (m: any) => void
}

export const QuickReply: React.FC<Props> = ({ reply, color, isFocusReply, isLarge, postMessage }) => {
  const preventFocus = usePreventFocus()
  const [isFocused, setIsFocused] = useState(false)
  const [isAnimation, setIsAnimation] = useState(false)
  const refReply = useRef(null)

  useEffect(() => {
    setIsAnimation(true)
  }, [])

  useEffect(() => {
    if (isFocusReply) setTimeout(() => refReply?.current?.focus(), 1000)
  }, [isFocusReply])

  const handleClick = () => {
    const postBack = generatePostBackObject(reply)
    postMessage(postBack)
  }

  const onFocus = () => setIsFocused(true)
  const onBlur = () => setIsFocused(false)

  return (
    <S.QuickReply
      color={color}
      data-autotest={'quick-reply'}
      onClick={handleClick}
      tabIndex={preventFocus ? -1 : 0}
      onFocus={onFocus}
      onBlur={onBlur}
      aria-label={`${reply.title} ${isFocused ? '' : '- button.'}`}
      ref={refReply}
      isAnimation={isAnimation}
      isLarge={isLarge}>
      <span>{reply.title}</span>
    </S.QuickReply>
  )
}
