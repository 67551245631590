export const mockWidgetSettings = {
  botName: 'Test58',
  welcomeTitle: 'Hey there!',
  welcomeSubtitle: 'Ready to start a journey with our chatbot?',
  widgetName: 'Chatbot',
  logo: '/default_avatar.png',
  color: '#3a87f0',
  isLoginNameInputEnabled: true,
  isLoginEmailInputEnabled: false,
  isBackgroundBlurred: false,
  isComposerInputEnabled: true,
  chatHistoryLevel: 'LOCAL_STORAGE',
  startButton: 'Start',
  nameInputPlaceholder: 'Your name',
  nameInputError: 'Username can only contain letters, spaces, and hyphens',
  emailInputPlaceholder: 'Your email',
  emailInputError: 'Email is not valid',
  emailInputEmptyError: 'Email should not be blank',
  startLabel: 'Jump into the chat by entering your info',
  inputPlaceholder: 'Write a reply...',
  isRtl: false,
  hasPersistentMenu: true,
  endConversation: {
    title: 'End conversation',
    subtitle: "Do you want to end conversation? We won't be able to send you messages anymore.",
  },
  rating: {
    title: 'How would  you rate the conversation?',
    commentInputPlaceholder: 'Type comment here',
  },
  widgetActions: {
    maximizeWidgetTitle: 'Maximize widget',
    minimizeWidgetTitle: 'Minimize widget',
    soundOnTitle: 'Sound on',
    soundOffTitle: 'Sound off',
    endConversationTitle: 'End conversation',
  },
  unreadMessagesTitle: 'Unread messages',
  failMessageSendTitle: 'Message failed to send. Do you want to try again?',
  promptButtons: {
    endTitle: 'End',
    cancelTitle: 'Cancel',
    sendTitle: 'Send',
    tryAgainTitle: 'Try again',
  },
  widgetSettings: {
    doEnableSoundNotifications: true,
    doShowPopupMessagePreview: true,
    doAskForRating: true,
    doEnableAttachments: null,
  },
  statusesLabels: {
    agentJoined: 'Agent joined the conversation',
    agentLeft: 'Agent left the conversation',
    userEnded: 'You ended the conversation',
    sessionExpired: 'Session expired',
  },
}
