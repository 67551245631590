import React from 'react'

import { templateImgStyle } from './config'
import { zoomImage } from 'actions/helpers'
import { DeviceType } from 'models/DeviceType'
import classes from './styles.module.scss'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { ReactionType } from 'models/MessageType'

interface Props {
  url: string
  device: DeviceType
  parentURL: string
  isFullScreenWidget: boolean
  enableReaction: boolean
  addReaction: (e: any, reactionValue: string, messageId: string) => void
  isFocusButton: boolean
  isMobile: boolean
  messageId: string
  reaction: ReactionType
}

const ImageMessage: React.FC<Props> = ({ url, device, parentURL, enableReaction }) => {
  const preventFocus = usePreventFocus()
  const imageName = url?.substring(url?.lastIndexOf('/') + 1)

  const onClickHandler = () => {
    zoomImage(url, parentURL)
  }

  return (
    <div
      tabIndex={preventFocus ? -1 : 0}
      aria-label={`image message: ${imageName}`}
      className={classes.templateImg}
      style={templateImgStyle(device, url, enableReaction)}
      onClick={onClickHandler}
    />
  )
}

export default ImageMessage
