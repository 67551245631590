import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const convertUserMessageToBepFormat = (chatId, message, websiteLocation) => {
  return {
    message: {
      ...message,
      id: uuidv4(),
      timestamp: moment().utc().format('YYYY-MM-DD[T]HH:mm:ss'),
    },
    chatId,
    websiteLocation,
  }
}

export { convertUserMessageToBepFormat }
