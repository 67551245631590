import React, { useEffect, useState } from 'react'
import { StarIcon } from 'icons/Icons'
import { PERSIMMON_RED } from 'constants/colors'
import { SettingsType } from 'models/SettingsType'
import * as S from './FormRating.style'

const ratingArray = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }]

interface Props {
  handleChange: (a: number) => void
  isError: boolean
  settings: SettingsType
}

export const FormRating: React.FC<Props> = ({ handleChange, isError, settings }) => {
  const [rating, setRating] = useState(0)

  useEffect(() => {
    handleChange(rating)
  }, [rating])

  const onChangeRating = value => {
    if (value) {
      setRating(+value)
    }
  }

  const setColor = value => {
    if (isError) return PERSIMMON_RED
    if (value <= rating) return settings.color
  }

  return (
    <S.FormRating>
      {ratingArray.map((item) => (
        <S.Button
          aria-label={`${item.value} stars`}
          key={item.value}
          onClick={() => onChangeRating(item.value)}
          tabIndex={1}
          data-testid={`button-${item.value}`}>
          <StarIcon color={setColor(item.value)} />
        </S.Button>
      ))}
    </S.FormRating>
  )
}
