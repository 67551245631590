import React, { useEffect, useRef, useState } from 'react'
import * as S from './QuickReplyMultiSelect.style'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { MarkIcon } from 'icons/Icons'

interface Props {
  reply: any
  color: string
  isLarge: boolean
  isSelected?: boolean
  isSubmitButton?: boolean
  showSubmitButton?: boolean
  onClick: (m: any) => void
}

export const QuickReplyMultiSelect: React.FC<Props> = ({
  reply,
  color,
  isLarge,
  onClick,
  isSelected,
  isSubmitButton,
  showSubmitButton,
}) => {
  const preventFocus = usePreventFocus()
  const [isAnimation, setIsAnimation] = useState(false)
  const refReply = useRef(null)

  useEffect(() => {
    setIsAnimation(true)
  }, [])

  const handleClick = () => onClick(reply)

  return (
    <S.QuickReplyMultiSelect
      color={color}
      data-autotest={'quick-reply'}
      onClick={handleClick}
      tabIndex={preventFocus ? -1 : 0}
      aria-label={`${reply.title} - button.`}
      ref={refReply}
      isAnimation={isAnimation}
      isLarge={isLarge}
      isSelected={isSelected}
      isSubmitButton={isSubmitButton}
      showSubmitButton={showSubmitButton}>
      <MarkIcon color={color}/>
      {reply.title}
    </S.QuickReplyMultiSelect>
  )
}
