export function generatePostBackObject(object) {
  return {
    type: 'postback',
    postback: {
      payload: object.payload,
      title: object.title,
      postbackId: object.postbackId,
    },
  }
}
