import styled from 'styled-components'
import { CORNFLOWER_BLUE } from 'constants/colors'

const Button = styled.button<{ color: string }>`
  background-color: ${({ color }) => color || CORNFLOWER_BLUE};
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  user-select: none;
  position: relative;
  border: none;
  &:first-child {
    transition: 0.4s all;
  }
`

const Logo = styled.img`
  width: 22px;
  animation-duration: 0.4s;
  animation-name: appear;
`

const Icon = styled.img`
  animation-duration: 0.4s;
  animation-name: appearIn;
`

const Container = styled.div<{ isButtonNeeded: string }>`
  position: relative;
  display: ${({ isButtonNeeded }) => !isButtonNeeded && 'none'};
`

export { Button, Logo, Icon, Container }
