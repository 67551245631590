import React from 'react'
import { messageWidth } from './config'
import classes from './styles.module.scss'
import { wrapTextAddLinks } from 'helper/wrapTextAddLinks'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { xssOptions } from 'config/xssOptions'
import xss from 'xss'

const TemplateText = ({ device, isRtl, title, subtitle }) => {
  const preventFocus = usePreventFocus()
  const direction = isRtl ? 'rtl' : 'ltr'

  return (
    <>
      {
        <div
          className={
            title || subtitle
              ? classes.templateTopWrapper
              : classes.templateTopWrapperWithOutTitles
          }
          style={messageWidth(device.width - 68)}>
          {title && (
            <p className={classes.templateTitle} style={{ direction }}>
              <span
                tabIndex={preventFocus ? -1 : 0}
                dangerouslySetInnerHTML={{ __html: xss(wrapTextAddLinks(title), xssOptions) }}
              />
            </p>
          )}
          {subtitle && (
            <p className={classes.templateSubtitle} style={{ direction }}>
              <span
                tabIndex={preventFocus ? -1 : 0}
                dangerouslySetInnerHTML={{ __html: xss(wrapTextAddLinks(subtitle), xssOptions) }}
              />
            </p>
          )}
        </div>
      }
    </>
  )
}

export default TemplateText
