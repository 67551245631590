import styled from 'styled-components'
import { RIBBON_BLUE, WHITE } from 'constants/colors'

const ModalButton = styled.button<{ color: string }>`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 4px;
  min-width: 80px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
  color: ${({ color, type }) => (type === 'cancel' ? color || RIBBON_BLUE : WHITE)};
  border: 1px solid ${({ color }) => color};
  background: ${({ color, type }) => (type === 'submit' ? color || RIBBON_BLUE : WHITE)};
  cursor: pointer;
`
export { ModalButton }
