import React from 'react'

import * as S from './StartScreenHeader.style'

export const StartScreenHeader = ({ settings }) => {
  return (
    <S.StartScreenHeader color={settings.color}>
      <S.Logo src={settings.logo} alt="bot logo" />

      <S.TextWrap>
        <S.Title dir={settings.isRtl ? 'rtl' : 'ltr'} tabIndex={0}>
          {settings.welcomeTitle}
        </S.Title>
        <S.SubTitle dir={settings.isRtl ? 'rtl' : 'ltr'} tabIndex={0}>
          {settings.welcomeSubtitle}
        </S.SubTitle>
      </S.TextWrap>
    </S.StartScreenHeader>
  )
}
