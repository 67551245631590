import React from 'react'

import CenteredLoader from '../../components/view/CenteredLoader'

import classes from './styles.module.scss'
import { useClickOutside } from '../../hooks/useClickOutside'

interface Props {
  onClose: () => void
  isLoading?: boolean
  ariaLabel: string
}

export const Modal: React.FC<Props> = props => {
  const ref = useClickOutside(props.onClose)

  return (
    <div className={classes.wrap} data-testid="modal">
      <div
        className={classes.modal}
        ref={ref}
        aria-label={`${props.ariaLabel} modal window`}
        tabIndex={1}
        data-testid="modal-content">
        {props.children}
        {props.isLoading && (
          <div className={classes.loader}>
            <CenteredLoader position={'relative'} size={50} />
          </div>
        )}
      </div>
    </div>
  )
}
