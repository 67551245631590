import styled from 'styled-components'
import { WHITE, MINE_SHAFT_BLACK, SPUN_PEARL_GREY, PERSIMMON_RED } from 'constants/colors'
import { MAX_INPUT_LENGTH } from 'constants/index'

const UserInput = styled.div<{ textDisabled?: boolean }>`
  display: ${({ textDisabled }) => (textDisabled ? 'flex' : 'none')};
  height: 60px;
  box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.07);
  background: ${WHITE};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
  transition: 0.4s ease-in-out all;
  position: relative;
`

const Input = styled.input`
  background: ${WHITE};
  width: 100%;
  height: 60px;
  visibility: ${props => (props.isHiddenTextInput ? 'hidden' : 'visible')};
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  padding: 20px 0 20px 24px;
  border-radius: 10px;
  color: ${MINE_SHAFT_BLACK};
  resize: none;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  transition: ${props =>
    (props.isHiddenTextInput
      ? 'opacity 300ms ease-out, visibility 1ms ease-out 300ms'
      : 'opacity 300ms ease-out 1ms, visibility 1ms ease-out')};
  &:focus {
    outline: 0;
    touch-action: none;
  }
`

const Button = styled.button`
  box-sizing: border-box;
  cursor: pointer;
  padding: 20px 24px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  visibility: ${props => (props.isHiddenTextInput ? 'hidden' : 'visible')};
  transition: ${props =>
    (props.isHiddenTextInput
      ? 'opacity 300ms ease-out, visibility 1ms ease-out 300ms'
      : 'opacity 300ms ease-out 1ms, visibility 1ms ease-out')};
`

const InputLengthIndicator = styled.div<{ length?: number }>`
  position: absolute;
  bottom: 8px;
  right: 69px;
  font-size: 10px;
  color: ${({ length }) => (length >= MAX_INPUT_LENGTH ? PERSIMMON_RED : SPUN_PEARL_GREY)};
  display: ${({ length }) => (length >= 200 ? 'block' : 'none')};
`

export { UserInput, Input, Button, InputLengthIndicator }
