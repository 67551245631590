import styled from 'styled-components'

const FormRating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0px;
`

const Button = styled.button`
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
`

export { FormRating, Button }
