/* eslint-disable */
import React from 'react'

export class PrevIcon extends React.Component {
  render() {
    return (
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
        <path d="M7 13L1 7L7 1" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class NextIcon extends React.Component {
  render() {
    return (
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
        <path d="M1 13L7 7L1 1" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class SendIcon extends React.Component {
  render() {
    return (
      <svg width={this.props.width} height={this.props.height} viewBox="0 0 21 21" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 10L20 1L11 20L9 12L1 10Z"
          stroke={this.props.color || 'rgb(90, 152, 247)'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}

export class DownloadIcon extends React.Component {
  render() {
    return (
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none">
        <path
          d="M1 7.66602V8.99935C1 9.49027 1.39797 9.88824 1.88889 9.88824H8.11111C8.60203 9.88824 9 9.49027 9 8.99935V7.66602"
          stroke="#1658F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.22217 5.44531L4.99995 7.22309L6.77772 5.44531"
          stroke="#1658F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M5 1V7.22222" stroke="#1658F3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class LineIcon extends React.Component {
  render() {
    return (
      <svg width="20" height="2" viewBox="0 0 20 2" fill="none">
        <path
          d="M1 1H19"
          stroke={this.props.color || 'rgb(90, 152, 247)'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}

export class BackIcon extends React.Component {
  render() {
    return (
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M17 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 13L1 7L7 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class ForwardIcon extends React.Component {
  render() {
    return (
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M1 7H17" stroke="#B0B2C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 13L17 7L11 1" stroke="#B0B2C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class ClosePopupIcon extends React.Component {
  render() {
    return (
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
          stroke="#616581"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 6.25L6.25 10.75"
          stroke="#616581"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.25 6.25L10.75 10.75"
          stroke="#616581"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}

export const CloseWidgetIcon = ({ width, height, color }) => (
  <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none">
    <path d="M18 6L6 18" stroke={color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke={color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const MinusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const TreeDotsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
      stroke="#FAFBFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
      stroke="#FAFBFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
      stroke="#FAFBFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MiniMazeIcon = ({ width, height, color }) => (
  <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none">
    <path d="M4 14H10V20" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 10H14V4" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10L21 3" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 21L10 14" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const SoundOffIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="#616581" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 9L17 15" stroke="#616581" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 9L23 15" stroke="#616581" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const SoundOnIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 5L6 9H2V15H6L11 19V5Z"
      stroke="#616581"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.07 4.92993C20.9447 6.80521 21.9979 9.34829 21.9979 11.9999C21.9979 14.6516 20.9447 17.1947 19.07 19.0699M15.54 8.45993C16.4774 9.39757 17.004 10.6691 17.004 11.9949C17.004 13.3208 16.4774 14.5923 15.54 15.5299"
      stroke="#616581"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const MaxiMazeIcon = ({ width, height, color }) => (
  <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none">
    <path d="M3 15V21H9" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21 9L21 3L15 3"
      stroke={color || '#616581'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 3L14 10" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 21L10 14" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export class LocationIcon extends React.Component {
  render() {
    return (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <circle cx="11" cy="11" r="11" fill="#1658F3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 10.0909C15 13.2727 11 16 11 16C11 16 7 13.2727 7 10.0909C7 7.83156 8.79086 6 11 6C13.2091 6 15 7.83156 15 10.0909V10.0909Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}

export const CopyIcon = props => {
  const { width, height, color } = props
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
      <path
        d="M17.1998 7.30002H9.0998C8.10569 7.30002 7.2998 8.10591 7.2998 9.10002V17.2C7.2998 18.1941 8.10569 19 9.0998 19H17.1998C18.1939 19 18.9998 18.1941 18.9998 17.2V9.10002C18.9998 8.10591 18.1939 7.30002 17.1998 7.30002Z"
        stroke={color || '#354052'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.7 12.7H2.8C2.32261 12.7 1.86477 12.5104 1.52721 12.1728C1.18964 11.8352 1 11.3774 1 10.9V2.8C1 2.32261 1.18964 1.86477 1.52721 1.52721C1.86477 1.18964 2.32261 1 2.8 1H10.9C11.3774 1 11.8352 1.18964 12.1728 1.52721C12.5104 1.86477 12.7 2.32261 12.7 2.8V3.7"
        stroke={color || '#354052'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const StarIcon = props => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z"
        stroke={props.color || '#B0B2C0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SendStatusIcon = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35355 0.646447C9.54882 0.841709 9.54882 1.15829 9.35355 1.35355L3.85355 6.85355C3.65829 7.04882 3.34171 7.04882 3.14645 6.85355L0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645C0.841709 3.45118 1.15829 3.45118 1.35355 3.64645L3.5 5.79289L8.64645 0.646447C8.84171 0.451184 9.15829 0.451184 9.35355 0.646447Z"
        fill="#B0B2C0"
      />
    </svg>
  )
}

export const ReadStatusIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_92_1706)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.85355 2.64645C9.04882 2.84171 9.04882 3.15829 8.85355 3.35355L3.35355 8.85355C3.15829 9.04882 2.84171 9.04882 2.64645 8.85355L0.146447 6.35355C-0.0488155 6.15829 -0.0488155 5.84171 0.146447 5.64645C0.341709 5.45118 0.658291 5.45118 0.853553 5.64645L3 7.79289L8.14645 2.64645C8.34171 2.45118 8.65829 2.45118 8.85355 2.64645ZM11.8536 2.64645C12.0488 2.84171 12.0488 3.15829 11.8536 3.35355L6.35355 8.85355C6.15829 9.04882 5.84171 9.04882 5.64645 8.85355L5.07747 8.28458C4.88221 8.08932 4.88221 7.77274 5.07747 7.57747C5.27274 7.38221 5.58932 7.38221 5.78458 7.57747L6 7.79289L11.1464 2.64645C11.3417 2.45118 11.6583 2.45118 11.8536 2.64645Z"
          fill="#B0B2C0"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_1706">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const MarkIcon = props => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 0.195262C12.0651 0.455612 12.0651 0.877722 11.8047 1.13807L4.4714 8.47141C4.21106 8.73175 3.78894 8.73175 3.5286 8.47141L0.195262 5.13807C-0.0650874 4.87772 -0.0650874 4.45561 0.195262 4.19526C0.455612 3.93491 0.877722 3.93491 1.13807 4.19526L4 7.05719L10.8619 0.195262C11.1223 -0.0650874 11.5444 -0.0650874 11.8047 0.195262Z"
        fill={props.color || '#1658F3'}
      />
    </svg>
  )
}
