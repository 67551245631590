import React from 'react'
import {
  ReactionButton,
  ReactionContainer,
  ReactionIconContainer,
  ReactionText,
  ReactionWrapper
} from './ReactionBarSelector.style'
import { ReactionType } from 'models/MessageType'
import { reactionsModelData, reactionsIconsCollection } from 'constants/reactions'

interface Props {
  onClick: (e: any, reactionValue: string, messageId: string) => void
  isMobile: boolean
  reaction: ReactionType
  messageId: string
  isAttachment?: boolean
  showReactionElementText: boolean
}

export const ReactionBarSelector: React.FC<Props> = ({
  onClick,
  isMobile,
  reaction,
  messageId,
  isAttachment,
  showReactionElementText
}) => {
  const isPickedReaction = !!reaction

  return (
    <ReactionWrapper
      showElement={showReactionElementText}
      isPickedReaction={isPickedReaction}
      isAttachment={isAttachment}
    >
      {reaction ? (
        <ReactionIconContainer>
          {reactionsIconsCollection[reaction.value]}
        </ReactionIconContainer>
      ) : (
        <>
          {showReactionElementText && <ReactionText>Was this helpful?</ReactionText>}
          <ReactionContainer>
            {reactionsModelData.map(reaction => (
              <ReactionButton
                key={reaction.value}
                onClick={(e) => onClick(e, reaction.value, messageId)}
                isMobile={isMobile}
              >
                {reaction.icon}
              </ReactionButton>
            ))}
          </ReactionContainer>
        </>
      )}
    </ReactionWrapper>
  )
}
