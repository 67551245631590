import { urlPattern, usPhoneNumberPattern } from '../actions/helpers'

export const wrapTextAddLinks = (text, preventFocus) => {
  text = findPhoneLink(text, preventFocus)
  text = findUrlLink(text, preventFocus)
  text = convertBoldText(text)
  text = replaceSpanWithA(text)

  return text
}

const findPhoneLink = (text, preventFocus) => {
  if (!usPhoneNumberPattern.test(text)) return text

  return text.replace(
    usPhoneNumberPattern,
    url => `<a class="link" href="tel:${url}" tabIndex=${preventFocus ? -1 : 0}>${url}</a>`,
  )
}

const findUrlLink = (text, preventFocus) => {
  const textArr = text?.split(' ')
  if (textArr) {
    textArr.forEach((word, index) => {
      if (urlPattern.exec(word) && !word.includes('data-href') && !word.endsWith('.text')) {
        word = word.replace(
          urlPattern,
          url =>
            `<a href="${withHttp(url)}" class="linkCreated" target="_newTab${index}" tabIndex=${
              preventFocus ? -1 : 0
            }>${url}</a>`,
        )
        textArr[index] = word
      }
    })
    return textArr.join(' ')
  }
}

const withHttp = link => {
  if (!/[a-zA-Z0-9]/.test(link.slice(-1))) link = link.slice(0, -1)
  const url = !/^https?:\/\//i.test(link) ? `http://${link}` : link
  return ' ' + url.trim() + ' '
}

const convertBoldText = text => {
  return text.replace(/\*(.*?)\*/g, '<b>$1</b>')
}

const replaceSpanWithA = text => {
  return text.replace(/(<span)/gim, '<a').replace(/<\/span>/gim, '</a>')
}
