import React, { useContext, useEffect } from 'react'
import { ClosePopupIcon } from 'icons/Icons'
import { UnreadMessagesContext } from 'context/UnreadMessagesContext'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import * as S from './MessagesPreview.style'

export const MessagesPreview = ({
  device,
  submitPopup,
  greeting,
  authorized,
  settings,
  messages,
  isPopupsNeeded,
  websiteLocation,
}) => {
  const { unreadMessagesList, handleDeleteUnreadMessages } = useContext(UnreadMessagesContext)
  const { initializeConversation }: any = useContext(WebSocketsSharedContext)

  const listenerClickGreeting = event => {
    if (event.data.type === 'submit-greeting') {
      handleSubmitPopup(event.data.message)
    }
    if (event.data.type === 'close-greeting') {
      handleClosePopup(null, event.data.message)
    }
  }

  useEffect(() => {
    window.addEventListener('message', listenerClickGreeting)
    return () => {
      window.removeEventListener('message', listenerClickGreeting)
    }
  }, [listenerClickGreeting])

  const handleClosePopup = (e, message) => {
    e?.stopPropagation()
    handleDeleteUnreadMessages(message.id, websiteLocation)
  }

  const handleSubmitPopup = message => {
    if (message.isWidgetPopup) {
      submitWidgetPopup()
    } else {
      submitPopup()
    }
  }

  const submitWidgetPopup = () => {
    const isNoStartScreen = !settings.isLoginNameInputEnabled && !settings.isLoginEmailInputEnabled

    submitPopup()
      .then(() => {
        if (greeting?.payload && authorized) {
          const message = {
            type: 'postback',
            payload: greeting.payload,
            isRtl: false,
          }
          initializeConversation(message)
        } else if (!messages.length && isNoStartScreen) {
          initializeConversation()
        }
      })
  }

  return (
    <S.MessagesPreview isPopupsNeeded={isPopupsNeeded}>
      {unreadMessagesList?.map(message => (
        <S.Popup
          key={message.id}
          onClick={() => handleSubmitPopup(message)}
          width={device.width}
          tabIndex={0}
          aria-label={`popup message: ${message?.text}`}>
          <S.Icon
            onClick={e => handleClosePopup(e, message)}
            aria-label="close popup - button"
            data-testid="toggle"
            tabIndex={0}>
            <ClosePopupIcon />
          </S.Icon>
          <S.Text>{message?.text}</S.Text>
        </S.Popup>
      ))}
    </S.MessagesPreview>
  )
}
