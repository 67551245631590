import styled from 'styled-components'
import { WHITE } from 'constants/colors'

const TemplateMessage = styled.div`
  position: relative;
  min-height: 140px;
  width: 100%;
  @media screen and (max-width: 450px) {
    box-sizing: content-box;
    padding-right: 0;
    width: 100%;
  }
`

const IconWrap = styled.div`
  position: absolute;
  top: 36%;
  z-index: 1;
  background: ${WHITE};
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.15);
  width: 25px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.5s ease-in-out;
  user-select: none;
  border: none;
  &:hover {
    opacity: 1;
  }
`

const PrevIconWrap = styled(IconWrap)`
  left: 0;
  padding: 5px 7px 5px 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`

const NextIconWrap = styled(IconWrap)`
  right: 26px;
  padding: 5px 0 5px 7px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
`

const SlidesWrap = styled.div<{ isFullScreenWidget: boolean; showLogo: boolean; isMobile: boolean }>`
  display: flex;
  align-items: stretch;
  overflow: hidden;
  scroll-behavior: smooth;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: calc(100% - ${({ isFullScreenWidget, showLogo }) => (isFullScreenWidget || showLogo ? 26 : 36)}px);
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

export { TemplateMessage, PrevIconWrap, NextIconWrap, SlidesWrap }
