import React, { useContext, useEffect, useMemo, useState } from 'react'
import QuickReplyMultiSelect from '../QuickReplyMultiSelect'
import * as S from './QuickReplyMultiSelectList.style'
import { hasAttributeValue } from 'helper/hasAttributeValue'
import { parseAttributes } from 'helper/parseAttributes'
import { generateMultiSelectPostBack } from 'helper/generateMultiSelectPostBack'
import { IS_BUTTON_LARGE, IS_SUBMIT_BUTTON, MAX_MULTI_SELECT } from 'constants/replyAttributes'
import { ScrollToButtonContext } from 'context/ScrollToButtonContext'

interface Props {
  message: any
  color: string
  postMessage: (m: any) => void
}

export const QuickReplyMultiSelectList: React.FC<Props> = ({ message, color, postMessage }) => {
  const [showReplies, setShowReplies] = useState(true)
  const [showAnimation, setShowAnimation] = useState(false)
  const [selectedQR, setSelectedQR] = useState([])

  const { scrollToBottom } = useContext(ScrollToButtonContext)

  useEffect(() => {
    if (selectedQR.length) {
      scrollToBottom()
    }
  }, [selectedQR.length])

  const isLarge = useMemo(() => {
    return message?.quickReplies?.some(reply => hasAttributeValue(reply, IS_BUTTON_LARGE, 'true'))
  }, [])

  const maxMultiSelect = useMemo(() => {
    return message?.quickReplies
      ?.reduce((prev, curr) => [...prev, ...parseAttributes(curr.payload)], [])
      ?.find(({ name }) => name === MAX_MULTI_SELECT)?.value || message?.quickReplies?.length
  }, [])

  const handlePost = reply => {
    setShowAnimation(true)

    setTimeout(() => {
      const selectedQRObj = message?.quickReplies?.filter(({ title }) => selectedQR.includes(title))
      const sendReply = generateMultiSelectPostBack(reply, selectedQRObj)
      setShowReplies(false)
      postMessage(sendReply)
    }, 700)
  }

  const handleClick = reply => {
    const isSubmitButton = hasAttributeValue(reply, IS_SUBMIT_BUTTON, 'true')
    if (isSubmitButton) {
      handlePost(reply)
    } else if (selectedQR.includes(reply.title)) {
      setSelectedQR(selectedQR.filter(title => title !== reply.title))
    } else if (parseInt(maxMultiSelect) > selectedQR.length) {
      setSelectedQR([...selectedQR, reply.title])
    }
  }

  return (
    <S.QuickReplyMultiSelectList showReplies={showReplies} showAnimation={showAnimation} isLarge={isLarge}>
      {message?.quickReplies?.map((reply, index) => (
        <QuickReplyMultiSelect
          onClick={handleClick}
          color={color}
          reply={reply}
          isLarge={isLarge}
          isSelected={selectedQR.includes(reply.title)}
          isSubmitButton={hasAttributeValue(reply, IS_SUBMIT_BUTTON, 'true')}
          showSubmitButton={!!selectedQR.length}
          key={index}
        />
      ))}
    </S.QuickReplyMultiSelectList>
  )
}
