import styled from 'styled-components'
import { WHITE, COMET_BLUE } from 'constants/colors'
import { ElementRef } from 'react'

const Popover = styled.div<{ eleRect: ElementRef<any>; targetRect: ElementRef<any> }>`
  position: absolute;
  box-shadow: 0px 4px 8px rgba(53, 64, 82, 0.32);
  border-radius: 4px;
  background: ${WHITE};
  z-index: 505;
  top: ${({ eleRect, targetRect }) => eleRect?.top - targetRect?.top + 25}px;
  right: 52px;
`

const Item = styled.button`
  height: 40px;
  min-width: 176px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  color: ${COMET_BLUE};
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 16px;
  & > div,
  & > svg {
    margin-right: 8px;
  }
`

export { Popover, Item }
