import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './app/App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
// eslint-disable-next-line no-unused-vars
import * as globalHelpers from './helper'
import { UnreadMessagesContext, UnreadMessagesProvider } from './context/UnreadMessagesContext'
import { EndConversationModalProvider } from './context/EndConversationContext'
import { FeedbackModalProvider } from './context/FeedbackModalContext'
import { PersistentMenuProvider } from './context/PersistentMenuContext'
import { IframeProvider } from './context/IframeContext'
import { AriaLiveProvider } from './context/AriaLiveContext'
import { ScrollToButtonProvider } from './context/ScrollToButtonContext'
import { BrowserStorageContext, BrowserStorageProvider } from './context/BrowserStorageContext'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0,
})

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <EndConversationModalProvider>
      <FeedbackModalProvider>
        <AriaLiveProvider>
          <UnreadMessagesProvider>
            <PersistentMenuProvider>
              <IframeProvider>
                <ScrollToButtonProvider>
                  <BrowserStorageProvider>
                    <BrowserStorageContext.Consumer>
                      {valueBrowserStorage => (
                        <UnreadMessagesContext.Consumer>
                          {valueUnreadMessages => (
                            <App
                              unreadMessagesList={valueUnreadMessages.unreadMessagesList}
                              handleAddUnreadMessages={valueUnreadMessages.handleAddUnreadMessages}
                              handleClearUnreadMessages={valueUnreadMessages.handleClearUnreadMessages}
                              isWidgetPopup={valueUnreadMessages.isWidgetPopup}
                              updateBrowserStorage={valueBrowserStorage.updateBrowserStorage}
                              browserStorage={valueBrowserStorage.browserStorage}
                              initializeStorage={valueBrowserStorage.initializeStorage}
                            />
                          )}
                        </UnreadMessagesContext.Consumer>
                      )}
                    </BrowserStorageContext.Consumer>
                  </BrowserStorageProvider>
                </ScrollToButtonProvider>
              </IframeProvider>
            </PersistentMenuProvider>
          </UnreadMessagesProvider>
        </AriaLiveProvider>
      </FeedbackModalProvider>
    </EndConversationModalProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
)
