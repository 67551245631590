export const TYPING_ON = 'typing_on'
export const TYPING_OFF = 'typing_off'
export const SHOW_INPUT = 'show_input'
export const SESSION_EXPIRED = 'session_expired'
export const AGENT_LEFT = 'agent_left'
export const AGENT_JOINED = 'agent_joined'
export const END_CONVERSATION = 'end_conversation'
export const START_CONVERSATION = 'start_conversation'
export const WELCOME_SCREEN_OPEN = 'welcome_screen_open'
export const WIDGET_OPEN = 'widget_open'
export const WIDGET_CLOSE = 'widget_close'
export const REACTION = 'reaction'
export const INPUT_ON = 'input_on'
export const INPUT_OFF = 'input_off'
export const EXPERT_BOT_ENABLED = 'expert_bot_enabled'
export const EXPERT_BOT_DISABLED = 'expert_bot_disabled'
