import styled from 'styled-components'
import { WHITE, COMET_BLUE, ATHENS_GREY } from 'constants/colors'

const ItemButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${WHITE};
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  color: ${COMET_BLUE};
  box-sizing: border-box;
  padding: 16px;
  border: none;
  border-bottom: 1px solid ${ATHENS_GREY};
  text-decoration: none;
  word-break: break-word;
  text-align: left;
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  @media screen and (max-width: 450px) {
    width: calc(100vw - 24px);
  }
`

export { ItemButton }
