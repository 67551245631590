import { useEffect, useRef } from 'react'

export function useClickOutside(onClose: () => void, targetRef?: any) {
  const ref = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target) && onClose) {
      if (
        targetRef === undefined ||
        !targetRef.current.contains(event.target)
      ) {
        onClose()
      }
    }
  }

  return ref
}
