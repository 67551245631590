import React, { useContext, useState } from 'react'
import { Modal } from '../Modal'
import { ModalButton } from '../ModalButton/ModalButton'
import { FeedbackModalContext } from 'context/FeedbackModalContext'
import { FormRating } from '../FormRating/FormRating'
import { sendFeedback } from 'api/api'
import { SettingsType } from 'models/SettingsType'
import { WebSocketsSharedContext } from 'integrations/WebSockets'
import { END_CONVERSATION } from '../../constants/actionsType'
import { sendAction } from 'api/api'

import * as S from './FeedbackModal.style'

interface Props {
  settings: SettingsType
  toggleWidget: () => void
}

export const FeedbackModal: React.FC<Props> = ({ settings, toggleWidget }) => {
  const [isError, setIsError] = useState(false)
  const [rating, setRating] = useState(null)
  const [comment, setComment] = useState('')
  const { setIsOpenFeedbackModal } = useContext(FeedbackModalContext)
  const { chatId, botId, finishChatWithAgent }: any = useContext(WebSocketsSharedContext)

  const handleUpdateRating = rating => {
    setIsError(false)
    setRating(rating)
  }

  const handleUpdateComment = e => {
    setComment(e.target.value)
  }

  const handleSubmit = () => {
    if (!rating) {
      setIsError(true)
    } else {
      const feedback = { comment, score: rating }
      sendFeedback(feedback, chatId, botId)
        .then(() => sendAction(END_CONVERSATION, botId, chatId))
        .then(() => {
          handleClose()
          toggleWidget()
          finishChatWithAgent()
        })
    }
  }

  const handleClose = () => {
    setIsOpenFeedbackModal(false)
  }

  return (
    <Modal onClose={handleClose} ariaLabel={'feedback'}>
      <S.Title tabIndex={1}>{settings?.rating?.title}</S.Title>
      <FormRating isError={isError} handleChange={handleUpdateRating} settings={settings} />
      <S.Textarea
        tabIndex={1}
        id="widget-feedback-comment-input"
        autoComplete="off"
        onChange={handleUpdateComment}
        placeholder={settings?.rating?.commentInputPlaceholder}
      />
      <S.ButtonsWrap>
        <ModalButton
          type="cancel"
          title={settings?.promptButtons?.cancelTitle}
          onClick={handleClose}
          color={settings.color}
        />
        <ModalButton
          type="submit"
          title={settings?.promptButtons?.sendTitle}
          onClick={handleSubmit}
          color={settings.color}
          id="widget-submit-feedback-button"
          dataTestId="submit-modal"
        />
      </S.ButtonsWrap>
    </Modal>
  )
}
