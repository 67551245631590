export const urlPattern =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gi

export const usPhoneNumberPattern = /(1\s?)?[(]?\d{3}[)]?[(\s)?-]\d{3}[\s-]\d{4}/gi

export const isEmptyString = (text: string): boolean => {
  if (text == null) return true
  text = text.trim()
  return text.length === 0 && !text.trim() && text.trim() !== ' '
}

export const zoomImage = (url: string | URL, parentURL: string): void => {
  window.parent.postMessage(
    {
      type: 'zoom-image',
      imageUrl: url,
    },
    parentURL,
  )
}

export const getQueryVariable = (variable: string): string | boolean => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (const element of vars) {
    const pair = element.split('=')
    if (pair[0] === variable) {
      return decodeURI(pair[1])
    }
  }
  return false
}

export const isElementTop = (el: HTMLElement | null): boolean => el && !el.scrollTop
