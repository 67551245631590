import React from 'react'
import classes from './styles.module.scss'
import { SettingsType } from 'models/SettingsType'
import { usePreventFocus } from 'hooks/usePreventFocus'

interface Props {
  firstUnreadMessageRef: any
  settings: SettingsType
}

export const NewMessagesLine: React.FC<Props> = ({
  firstUnreadMessageRef,
  settings,
}) => {
  const preventFocus = usePreventFocus()

  return (
    <div
      className={classes.container}
      ref={firstUnreadMessageRef}
      style={{ borderColor: settings.color }}>
      <span style={{ color: settings.color }} tabIndex={preventFocus ? -1 : 0}>
        {settings.unreadMessagesTitle}
      </span>
    </div>
  )
}
