import React from 'react'

const BrowserStorageContext = React.createContext({
  browserStorage: {
    unreadMessages: null,
    chatId: null,
  },
  updateBrowserStorage: undefined,
})

const BrowserStorageProvider = props => {
  const [browserStorage, setBrowserStorage] = React.useState({ unreadMessages: null, chatId: null })
  const [websiteLocation, setWebsiteLocation] = React.useState('')

  const updateBrowserStorage = storage => {
    setBrowserStorage({ ...browserStorage, ...storage })
    window.parent.postMessage(
      {
        type: 'update-local-storage',
        storage,
      },
      websiteLocation,
    )
  }

  const initializeStorage = (websiteLocation, storage) => {
    setWebsiteLocation(websiteLocation)
    setBrowserStorage(storage)
  }

  const value = React.useMemo(
    () => ({
      initializeStorage,
      browserStorage,
      updateBrowserStorage,
    }),
    [browserStorage, websiteLocation],
  )

  return <BrowserStorageContext.Provider value={value}>{props.children}</BrowserStorageContext.Provider>
}

export { BrowserStorageProvider, BrowserStorageContext }
