import React, { useContext, useEffect } from 'react'

import { CountUnreadMessagesContext } from '../../context/CountUnreadMessagesContext'
import classes from './styles.module.scss'

export const UnreadMessagesCounter = () => {
  const { unreadMessages, setUnreadMessages } = useContext(
    CountUnreadMessagesContext,
  )

  useEffect(() => {
    return () => {
      setUnreadMessages(0)
    }
  }, [])

  return (
    <div className={classes.wrap}>
      <p className={classes.count} aria-label={`${unreadMessages} unread messages`} tabIndex={0}>{unreadMessages}</p>
    </div>
  )
}
