import React, { useContext, useEffect } from 'react'
import { DeviceType } from 'models/DeviceType'
import prevIcon from 'icons/prevWhite.png'

import * as S from './Iframe.style'
import { EndConversationModalContext } from '../../context/EndConversationContext'
import { FeedbackModalContext } from '../../context/FeedbackModalContext'
import { IframeContext } from '../../context/IframeContext'

interface Props {
  device: DeviceType
  color: string
  isFullScreenWidget: boolean
  screenSize: DeviceType
}

export const Iframe: React.FC<Props> = ({ device, color, isFullScreenWidget, screenSize }) => {
  const { isOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { isOpenFeedbackModal } = useContext(FeedbackModalContext)
  const { iframeSrc, handleCloseIframe } = useContext(IframeContext)
  const preventFocus = isOpenEndConversationModal || isOpenFeedbackModal

  const listenerCloseIframeWidget = event => {
    if (event.data.type === 'widget-close-iframe') {
      handleCloseIframe()
    }
  }

  useEffect(() => {
    window.addEventListener('message', listenerCloseIframeWidget)
    return () => {
      window.removeEventListener('message', listenerCloseIframeWidget)
    }
  }, [listenerCloseIframeWidget])


  return (
    <>
      <S.Button
        onClick={handleCloseIframe}
        data-autotest={'close-Iframe'}
        color={color}
        tabIndex={preventFocus ? -1 : 0}>
        <S.Image src={prevIcon} alt="close iframe" />
      </S.Button>
      <S.Iframe
        aria-live={'assertive'}
        aria-label={'iframe is open'}
        src={iframeSrc}
        name={`${iframeSrc}_iframe`}
        title={`${iframeSrc}_iframe`}
        isFullScreenWidget={isFullScreenWidget}
        device={device}
        screenSize={screenSize}>
        <p>Your browser does not support iframes.</p>
      </S.Iframe>
    </>
  )
}
