import React, { useContext, useEffect, useRef, useState } from 'react'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { IframeContext } from 'context/IframeContext'
import { ButtonType } from 'models/MessageType'
import { removeURLParameter } from 'helper/removeURLParameter/removeURLParameter'

import * as S from './MessageButton.style'

interface Props {
  color?: string
  button: ButtonType
  title: string
  dataAutotest: string
  isFocusButton: boolean
  onClick: () => void
}

// eslint-disable-next-line max-len
export const MessageButton: React.FC<Props> = ({ onClick, color, button, title, isFocusButton, dataAutotest }) => {//NOSONAR
  const [isFocused, setIsFocused] = useState(false)
  const preventFocus = usePreventFocus()
  const refButton = useRef(null)
  const { handleOpenIframe } = useContext(IframeContext)

  const onFocus = () => setIsFocused(true)
  const onBlur = () => setIsFocused(false)

  useEffect(() => {
    if (isFocusButton) setTimeout(() => refButton?.current?.focus(), 1000)
  }, [isFocusButton])

  const handleClick = () => {
    if (button.type === 'postback') {
      onClick()
    } else if (button.type === 'reset') {
      window.location.reload()
    } else if (button.type === 'web_url') {
      if (button.isWebview) {
        handleOpenIframe(button.url)
      } else {
        const redirectUrl = new URL(button.url)
        const searchParams = redirectUrl.searchParams
        const siteUrl = searchParams.get('url')
        const target = siteUrl.includes('openInWidgetPage=true') ? '_parent' : '_blank'
        const newSiteUrl = removeURLParameter(siteUrl, 'openInWidgetPage')

        searchParams.set('url', newSiteUrl)
        redirectUrl.search = searchParams.toString()

        window.open(redirectUrl.toString(), target)
      }
    }
  }

  return (
    <S.MessageButton
      color={color}
      data-autotest={dataAutotest}
      tabIndex={preventFocus ? -1 : 0}
      aria-label={`${title} ${isFocused ? '' : '- button.'}`}
      ref={refButton}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={handleClick}>
      {title}
    </S.MessageButton>
  )
}
