import React, { useMemo, useRef } from 'react'
import xss from 'xss'

import { wrapTextAddLinks } from 'helper/wrapTextAddLinks'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { xssOptions } from 'config/xssOptions'
import { ATHENS_GREY } from 'constants/colors'
import { DeviceType } from 'models/DeviceType'
import { SettingsType } from 'models/SettingsType'
import { MessageType } from 'models/MessageType'

import { MessageWrapper } from '../MessageWrapper'
import { messageWidthText } from '../TextMessage'
import classes from './styles.module.scss'

interface GPTMessageProps {
  message: MessageType
  device: DeviceType
  settings: SettingsType
  showLogo: boolean
  isMobile: boolean
}

export const GPTMessage: React.FC<GPTMessageProps> = ({ message, device, settings }) => {
  const output = useRef(null)
  const color = useMemo(() => (message.isFromBot ? ATHENS_GREY : settings.color), [])
  const bubbleClass = useMemo(() => (message.isFromBot ? classes.bubbleReceived : classes.bubbleSent), [])
  const preventFocus = usePreventFocus()

  const GPTMessageBorder = '17px 17px 17px 2px'

  return (
    <MessageWrapper
      showLogo
      message={message}
      settings={settings}
      showReply={false}
      isLastMessage={false}
      showTimestamp={false}>
      <span ref={output} data-autotest="text-gpt-message-container">
        <span
          tabIndex={preventFocus ? -1 : 0}
          className={`${bubbleClass} ${classes.bubble}`}
          style={messageWidthText(device.width, color, GPTMessageBorder, message.isRtl)}
          dangerouslySetInnerHTML={{
            __html:
              xss(wrapTextAddLinks(message.text, preventFocus), xssOptions) +
              `<span class=${classes.gptBlinkingCursor} />`,
          }}
          data-autotest="text-gpt-message"
        />
      </span>
    </MessageWrapper>
  )
}
