import React from 'react'
import { LocationIcon } from 'icons/Icons'
import * as S from '../QuickReply/QuickReply.style'

interface Props {
  postMessage: (m: any) => void
}

export const GeoReply: React.FC<Props> = ({ postMessage }) => {
  const handleClick = () => {
    navigator.geolocation.getCurrentPosition(setPosition, handlerError)
  }

  const setPosition = geolocation => {
    const message = {
      type: 'location',
      title: 'Geo Position',
      payload: {
        coordinates: {
          lat: geolocation.coords.latitude,
          long: geolocation.coords.longitude,
        },
      },
    }
    postMessage(message)
  }

  const handlerError = error => console.log(error)

  return (
    <S.QuickReply onClick={handleClick}>
      <LocationIcon />
      <span>Send Location</span>
    </S.QuickReply>
  )
}
